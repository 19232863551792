@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.OutletExpansion {
	position: relative;
	height: pxToVw(710px);
}

@media only screen and (max-width: $mobileBreakPoint) {
	.OutletExpansion {
		height: pxToVwMobile(598px);
	}
}
