@import "src/styles/typographies.module";
@import 'src/styles/others.module';

.PartnershipInfo {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__title {
		margin: pxToVw(40px) 0 pxToVw(60px) 0;
		text-transform: uppercase;
		line-height: pxToVw(54px);
		color: #FAC402;
		font-size: pxToVw(40px);
		@include exo-2-bold;
	}

	&__content {
		max-width: pxToVw(422px);
		line-height: pxToVw(28px);
		color: #FFFFFF;
		font-size: pxToVw(18px);
		@include exo-2-light;

		&:nth-child(3) {
			margin-top: pxToVw(50px);
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.PartnershipInfo {
		&__title {
			margin: pxToVwMobile(38px) 0 pxToVwMobile(46px) 0;
			line-height: pxToVwMobile(35px);
			font-size: pxToVwMobile(24px);
			@include exo-2-black;
		}

		&__content {
			max-width: pxToVwMobile(314px);
			line-height: pxToVwMobile(26px);
			font-size: pxToVwMobile(16px);

			&:nth-child(3) {
				margin-top: pxToVwMobile(45px);
			}
		}
	}
}
