@import 'src/styles/others.module';

.Menu {
	overflow-x: hidden;
	&__header {
		margin-top: pxToVw(54px);
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Menu {
		&__header {
			margin-top: 0;
		}
	}
}
