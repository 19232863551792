@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.FlavorVariant {
	right: 0;
	display: flex;
	top: pxToVw(120px);
	position: absolute;
	width: pxToVw(700px);
	height: pxToVw(600px);
	flex-direction: column;
	&__imageContainer {
		width: 100%;
		position: relative;
		height: pxToVw(520px);
		img {
			left: 0;
			opacity: 0;
			width: 95%;
			display: none;
			position: absolute;
			&:first-child {
				opacity: 1;
				display: block;
			}
		}
		&:after {
			right: 0;
			opacity: 0.8;
			content: '';
			bottom: -25%;
			height: 150%;
			position: absolute;
			width: pxToVw(384px);
			background: linear-gradient(90deg, rgba(0, 0, 0, 0) 50%, #000000 100%);
		}
	}
	&__labelContainer {
		flex: 1;
		display: flex;
		position: relative;
		align-items: flex-end;
		justify-content: center;
		&__eachLabel {
			opacity: 0;
			display: none;
			color: #ffffff;
			position: absolute;
			text-align: center;
			font-size: pxToVw(12px);
			line-height: pxToVw(22px);
			text-transform: uppercase;
			letter-spacing: pxToVw(2px);
			@include exo-2-bold();
			p {
				@include exo-2-light();
			}
			&:first-child {
				opacity: 1;
				display: block;
			}
		}
	}
	&__navigationButton {
		padding: 0;
		cursor: pointer;
		border-radius: 50%;
		position: absolute;
		right: pxToVw(54px);
		width: pxToVw(85px);
		height: pxToVw(85px);
		bottom: pxToVw(206px);
		box-sizing: border-box;
		background-color: transparent;
		border: 1px solid rgba(255, 255, 255, 0.5);
		&:last-child {
			display: none;
		}
		svg {
			color: #FFFFFF;
			font-size: pxToVw(36px);
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.FlavorVariant {
		width: 100%;
		display: block;
		overflow: hidden;
		top: pxToVwMobile(330px);
		height: pxToVwMobile(300px);
		&__imageContainer {
			display: flex;
			justify-content: center;
			right: pxToVwMobile(-90px);
			height: pxToVwMobile(280px);
			&:after {
				content: none;
			}
			img {
				left: unset;
				width: pxToVwMobile(338px);
			}
		}
		&__labelContainer {
			bottom: 0;
			width: 100%;
			display: flex;
			position: absolute;
			align-items: center;
			right: pxToVwMobile(-90px);
			height: pxToVwMobile(42px);
			&__eachLabel {
				font-size: pxToVwMobile(10px);
				line-height: pxToVwMobile(18px);
				letter-spacing: pxToVwMobile(1.67px);
			}
		}
		&__navigationButton {
			bottom: 0;
			right: unset;
			left: pxToVwMobile(90px);
			width: pxToVwMobile(42px);
			height: pxToVwMobile(42px);
			&:last-child {
				display: block;
				left: pxToVwMobile(30px);
			}
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint;
	verticalSlideDesktop: pxToVw(16px);
	verticalSlideMobile: pxToVwMobile(8px);
	horizontalSlideDesktop: pxToVw(16px);
	horizontalSlideMobile: pxToVwMobile(8px);
}
