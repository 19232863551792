@import 'src/styles/others.module';

.BestSellers {
	position: relative;
	height: pxToVw(980px);
}

@media only screen and (max-width: $mobileBreakPoint) {
	.BestSellers {
		height: pxToVwMobile(980px);
	}
}
