@import 'src/styles/others.module';

.Flavors {
	height: pxToVw(1916px);
	position: relative;
	top: pxToVw(-80px);
	overflow: hidden;
	margin-bottom: pxToVw(-80px);

	&__content {
		position: relative;
		background: transparent;
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Flavors {
		height: pxToVwMobile(1000px);
		top: pxToVwMobile(0px);
		overflow: hidden;
		margin-bottom: pxToVwMobile(0px);
	}
}
