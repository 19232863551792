@import "src/styles/typographies.module";
@import 'src/styles/others.module';

.Content {
	z-index: 1;
	width: 100%;
	height: 100%;
	display: flex;
	top: pxToVw(24px);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	&__moonChickenLogo {
		width: pxToVw(180px);
	}

	&__progress {
		color: #FAC402;
		text-align: center;
		font-size: pxToVw(12px);
		margin-top: pxToVw(68px);
		line-height: pxToVw(28px);
		letter-spacing: pxToVw(2px);
		@include exo-2-semibold;
	}
}


@media only screen and (max-width: $mobileBreakPoint) {
	.Content {
		&__moonChickenLogo {
			width: pxToVwMobile(113px);
		}
		&__progress {
			font-size: pxToVwMobile(12px);
			margin-top: pxToVwMobile(44px);
			line-height: pxToVwMobile(28px);
			letter-spacing: pxToVwMobile(2px);
		}
	}
}
