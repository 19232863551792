@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.OutletExpansion {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__title {
		width: pxToVw(540px);
		margin-top: pxToVw(112px);
		color: #FFFFFF;
		font-size: pxToVw(40px);
		line-height: pxToVw(60px);
		text-align: center;
		text-transform: uppercase;
		@include exo-2-bold;

		span {
			color: #FAC402;
		}
	}

	&__button {
		margin-top: pxToVw(60px);
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.OutletExpansion {
	
		&__title {
			width: pxToVwMobile(320px);
			color: #FFFFFF;
			font-size: pxToVwMobile(24px);
			line-height: pxToVwMobile(34px);
			@include exo-2-black;
	
			span {
				color: #FAC402;
			}
		}
	
		&__button {
			margin-top: pxToVwMobile(74px);
		}
	}
}