$milk: #ffffff;

$black: #303030;
$blackLight: #707070;

$grey: #858c9b;
$greyLight: #bbc1ce;
$greyLighter: #f5f5f5;
$greyLightest: #fafafa;

$sauce: #a30926;
$sauceLight: #f33055;
$sauceLighter: #ff8ea4;
$sauceLightest: #fff3f3;
$sauceDark: #83001a;
$sauceDarker: #5c0012;
$sauceDarkest: #41040f;

$lettuce: #12a25f;
$lettuceLight: #59be8f;
$lettuceLighter: #a0dabf;
$lettuceLightest: #e6f7ef;
$lettuceDark: #0e824c;
$lettuceDarker: #0b6139;
$lettuceDarkest: #074126;

$banana: #fab300;
$bananaLight: #ffc100;
$bananaLighter: #fde199;
$bananaLightest: #fff7e5;
$bananaDark: #c88f00;
$bananaDarker: #966b00;
$bananaDarkest: #644800;

$mango: #ff8433;
$mangoLight: #fea56a;
$mangoLighter: #ffcead;
$mangoLightest: #fff3eb;
$mangoDark: #cc6a29;
$mangoDarker: #994f1f;
$mangoDarkest: #663514;

$skin: #f0d9c4;
$nose: #908376;
$aqua: #f5faff;

$overlay10: rgba(48, 48, 48, 0.1);
$overlay40: rgba(48, 48, 48, 0.4);
$overlay80: rgba(48, 48, 48, 0.8);

$reddish: linear-gradient(135deg, #e40f37 0%, #a30926 55.47%);

:export {
  milk: #ffffff;

  black: #303030;
  blackLight: #707070;

  grey: #858c9b;
  greyLight: #bbc1ce;
  greyLighter: #f5f5f5;
  greyLightest: #fafafa;

  sauce: #a30926;
  sauceLight: #f33055;
  sauceLighter: #ff8ea4;
  sauceLightest: #fff3f3;
  sauceDark: #83001a;
  sauceDarker: #5c0012;
  sauceDarkest: #41040f;

  lettuce: #12a25f;
  lettuceLight: #59be8f;
  lettuceLighter: #a0dabf;
  lettuceLightest: #e6f7ef;
  lettuceDark: #0e824c;
  lettuceDarker: #0b6139;
  lettuceDarkest: #074126;

  banana: #fab300;
  bananaLight: #ffc100;
  bananaLighter: #fde199;
  bananaLightest: #fff7e5;
  bananaDark: #c88f00;
  bananaDarker: #966b00;
  bananaDarkest: #644800;

  mango: #ff8433;
  mangoLight: #fea56a;
  mangoLighter: #ffcead;
  mangoLightest: #fff3eb;
  mangoDark: #cc6a29;
  mangoDarker: #994f1f;
  mangoDarkest: #663514;

  skin: #f0d9c4;
  nose: #908376;
  aqua: #f5faff;

  overlay10: rgba(48, 48, 48, 0.1);
  overlay40: rgba(48, 48, 48, 0.4);
  overlay80: rgba(48, 48, 48, 0.8);

  reddish: linear-gradient(135deg, #e40f37 0%, #a30926 55.47%);
}
