@import 'src/styles/others.module';

.MenuBackground {
	width: 100%;
	position: relative;
	height: pxToVwMobile(667px);
	&__imageBackground {
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		img {
			top: 0;
			position: absolute;
		}
		&__planet {
			width: 100%;
			opacity: 0.5;
			filter: saturate(240%) brightness(94%);
		}
		&__planetTexture {
			width: 100%;
			opacity: 0.25;
		}
		&__smallMeteor {
			right: pxToVwMobile(36px);
			width: pxToVwMobile(50px);
			top: pxToVwMobile(86px) !important;
		}
		&__largeMeteor {
			width: pxToVwMobile(108px);
			right: pxToVwMobile(-84px);
			top: pxToVwMobile(120px) !important;
		}
		&__meteor3 {
			width: pxToVwMobile(82px);
			left: pxToVwMobile(46px);
			top: pxToVwMobile(530px) !important;
			transform: rotate(10deg);
			filter: brightness(1.2);
			z-index: 3;
			opacity: 0.2;
		}
		&:after {
			left: 0;
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: pxToVwMobile(8px);
			background-position: pxToVwMobile(5px);
			background-size: pxToVwMobile(14px) pxToVwMobile(14px);
			background-image: radial-gradient(rgba(255, 255, 255, 0.05) pxToVwMobile(1px), transparent 0);
		}
	}
	&__backgroundFilter {
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		&:before {
			top: 0;
			z-index: 1;
			width: 100%;
			content: '';
			position: absolute;
			height: pxToVwMobile(480px);
			background: radial-gradient(circle at 48.8% 41.5%, rgba(0,0,0,0) 70%, rgba(0, 0, 0, 1) 75%);
		}
		&:after {
			bottom: 0;
			z-index: 2;
			width: 100%;
			content: '';
			position: absolute;
			background: #000000;
			top: pxToVwMobile(480px);
		}
		&__innerFilter {
			z-index: 2;
			height: 100%;
			position: relative;
			transform: scale(3.2) translateY(20%);
			&__gridLine {
				z-index: 1;
				width: 100%;
				opacity: 0.85;
				position: relative;
			}
			&:before, &:after {
				top: 0;
				z-index: 2;
				width: 50%;
				content: '';
				height: 100%;
				opacity: 0.8;
				position: absolute;
			}
			&:before {
				background: linear-gradient(90deg, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
			}
			&:after {
				right: 0;
				background: linear-gradient(90deg, rgba(0,0,0,0) 42%, rgba(0,0,0,0.5) 75%, rgba(0,0,0,.8) 100%);
			}
			&__bottomGradient {
				bottom: 0;
				z-index: 3;
				width: 100%;
				position: absolute;
				height: pxToVwMobile(200px);
				background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
			}
		}
	}
}
