@import 'src/styles/others.module';

.Description {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding-top: pxToVw(40px);
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Description {
		padding-top: pxToVwMobile(40px);
	}
}
