@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

$scrollingBackgroundDuration: 300ms;
$menuContainerTransitionDuration: 400ms;

.HeaderContainer {
	z-index: 4;
	width: 100%;
	display: flex;
	position: absolute;
	flex-direction: column;
	&__backdrop {
		top: 0;
		width: 100%;
		height: 100%;
		position: absolute;
	}
}

.Header {
	top: 0;
	z-index: 4;
	width: 100%;
	display: flex;
	overflow: hidden;
	position: absolute;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	transition-duration: $menuContainerTransitionDuration;
	&:before {
		top: 0;
		opacity: 0;
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		transition-duration: inherit;
		background: rgba(0, 0, 0, 0.25);
		backdrop-filter: blur(pxToVwMobile(12px));
	}
	&__scrolled:before {
		display: none;
	}
	&__menuContainer {
		width: 100%;
		height: 100vh;
		display: none;
		cursor: pointer;
	}
	&__logo {
		z-index: 1;
		position: relative;
		width: pxToVw(100px);
	}
	&__inner {
		z-index: 1;
		width: 100%;
		display: flex;
		position: relative;
		align-items: center;
		height: pxToVw(65px);
		max-width: pxToVw(980px);
	}
	&__menu {
		flex: 1;
		display: flex;
		align-items: center;
		margin-left: pxToVw(164px);
		&__navlink {
			color: #ffffff;
			text-decoration: none;
			font-size: pxToVw(12px);
			text-transform: uppercase;
			letter-spacing: pxToVw(2px);
			@include exo-2-medium;
			&:not(:last-child) {
				margin-right: pxToVw(42px);
			}
		}
		&--active {
			color: #FAC402;
			text-shadow: 0 0 pxToVw(8px) #FAC402;
		}
	}
	&__hamburgerButton {
		padding: 0;
		border: none;
		display: none;
		outline: none;
		cursor: pointer;
		position: relative;
		flex-direction: column;
		width: pxToVwMobile(32px);
		background-color: transparent;
		div {
			width: 100%;
			height: pxToVwMobile(1px);
			background-color: #FAC400;
			transition-duration: 500ms;
			margin: pxToVwMobile(10px) 0;
			transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
		}
		&:before, &:after {
			content: '';
			width: 100%;
			position: relative;
			background-color: #FAC400;
			height: pxToVwMobile(1px);
			transition-duration: 500ms;
			transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
		}
	}
	&__hamburgerButton--active {
		div {
			width: 0;
		}
		&:before {
			transform: rotateZ(45deg) translate(pxToVwMobile(8px), pxToVwMobile(8px));
		}
		&:after {
			transform: rotateZ(-45deg) translate(pxToVwMobile(8px), pxToVwMobile(-8px));
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.HeaderContainer {
		position: fixed;
	}
	.Header {
		border-radius: 0 0 pxToVwMobile(20px) pxToVwMobile(20px);
		&__scrolled:before {
			display: block;
			opacity: 1;
		}
		&__logo {
			width: pxToVwMobile(62px);
		}
		&__inner {
			height: pxToVwMobile(90px);
			max-width: pxToVwMobile(315px);
			justify-content: space-between;
		}
		&__menu {
			display: none;
		}
		&__hamburgerButton {
			display: flex;
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint;
	scrollingBackgroundDuration: $scrollingBackgroundDuration;
	menuContainerTransitionDuration: $menuContainerTransitionDuration;
}
