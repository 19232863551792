@import 'src/styles/others.module';
@import 'src/styles/typographies.module';

.OutletLocationMap {
	width: 100%;
	position: relative;
	min-height: pxToVw(824px);
	&:before {
		z-index: 1;
		content: '';
		height: 100%;
		position: absolute;
		pointer-events: none;
		width: pxToVw(490px);
		background:
			linear-gradient(68deg, #121141 5%, rgba(18, 17, 65, 0.5) 25%, transparent 50%, transparent 80%),
			linear-gradient(110deg, rgba(16, 22, 29, 0.95) 40%, transparent 65%),
			linear-gradient(to right, #121141 10%, rgba(23, 31, 48, 0.25) 70%, transparent 95%);
	}
	&__map {
		right: 0;
		width: 80%;
		height: 100%;
		position: absolute;
	}
	&__gradientBackgroundMobile {
		top: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		pointer-events: none;
		display: none !important;
		transition: background-color;
	}
	&__backgroundOutletList {
		background:
			linear-gradient(
				to top,
				#121141 3%,
				rgba(18, 17, 65, 0.4) 20%,
				rgba(11,18,22,0.57) 80%
			),
			linear-gradient(
				to top,
				#121141 3%,
				rgba(18, 17, 65, 0.2) 90%,
				rgba(11,18,22,0) 85%
			);
	}
	&__backgroundOutletDetail {
		opacity: 0;
		display: none;
		background: linear-gradient(to top, #121141 20%, rgb(18, 17, 65, 0.5) 40%, transparent 70%);
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.OutletLocationMap {
		min-height: pxToVwMobile(680px);
		&:before {
			content: unset;
		}
		&__map {
			width: 100%;
			height: 80%;
		}
		&__legend {
			display: none !important;
		}
		&__gradientBackgroundMobile {
			display: unset !important;
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint;
}
