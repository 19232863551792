@import 'src/styles/others.module';
@import 'src/styles/typographies.module';

.OutletLocationMapLegend {
	bottom: 0;
	width: 100%;
	height: 100%;
	display: flex;
	position: absolute;
	pointer-events: none;
	align-items: flex-end;
	justify-content: center;
	background: radial-gradient(circle at 75% -10%, rgba(0,0,0,0) 50%, #121141 65%);
	&__desc {
		display: flex;
		align-items: center;

		&__text {
			margin-left: pxToVw(40px);
			opacity: 0.59;
			text-transform: uppercase;
			color: #FFFFFF;
			font-size: pxToVw(12px);
			letter-spacing: pxToVw(3px);
			line-height: pxToVw(28px);
			@include exo-2-regular;
		}
		&:first-child {
			margin-right: pxToVw(80px);
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.OutletLocationMapLegend {
		width: 100%;
		bottom: unset;
		background: none;
		box-sizing: border-box;
		align-items: flex-start;
		height: pxToVwMobile(32px);
		justify-content: flex-start;
		margin-top: pxToVwMobile(20px);
		padding-left: pxToVwMobile(4px);
		&__desc {
			display: flex;
			align-items: baseline;
			margin-right: pxToVwMobile(48px);

			&__text {
				margin-left: pxToVwMobile(36px);
				font-size: pxToVwMobile(12px);
				letter-spacing: pxToVwMobile(3px);
				line-height: pxToVwMobile(16px);
				max-width: pxToVwMobile(120px);
				@include exo-2-regular;

			}
		}
	}
}
