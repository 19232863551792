@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.PartnershipDescription {
	top: pxToVw(167px);
	position: absolute;
	left: pxToVw(298px);
	width: pxToVw(514px);
	&__headerText {
		color: #ffffff;
		font-size: pxToVw(40px);
		line-height: pxToVw(54px);
		text-transform: uppercase;
		@include exo-2-bold();
		p {
			color: #FAC402;
			display: inline-block;
		}
	}
	&__descriptionText {
		color: #ffffff;
		width: pxToVw(306px);
		font-size: pxToVw(18px);
		margin-top: pxToVw(42px);
		line-height: pxToVw(28px);
		@include exo-2-light();
	}
	&__outletPartnershipButton {
		margin-top: pxToVw(40px);
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.PartnershipDescription {
		left: unset;
		width: 100%;
		box-sizing: border-box;
		top: pxToVwMobile(147px);
		padding: 0 pxToVwMobile(30px);
		&__headerText {
			display: block;
			width: pxToVwMobile(300px);
			font-size: pxToVwMobile(24px);
			line-height: pxToVwMobile(34px);
		}
		&__descriptionText {
			width: pxToVwMobile(280px);
			font-size: pxToVwMobile(16px);
			margin-top: pxToVwMobile(30px);
			line-height: pxToVwMobile(26px);
		}
		&__outletPartnershipButton {
			margin-top: pxToVwMobile(52px);
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint
}
