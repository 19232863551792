@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.ExplorationDetail {
	z-index: 2;
	top: pxToVw(68px);
	position: relative;
	margin-left: pxToVw(164px);
	&__headingText {
		color: #ffffff;
		font-size: pxToVw(40px);
		line-height: pxToVw(54px);
		text-transform: uppercase;
		@include exo-2-bold();
		p {
			color: #FAC402;
			display: inline-block;
		}
	}
	&__descriptionText {
		color: #ffffff;
		font-size: pxToVw(18px);
		max-width: pxToVw(396px);
		margin-top: pxToVw(84px);
		line-height: pxToVw(28px);
		@include exo-2-light();
	}
	&__seeLocationsButton {
		z-index: 1;
		position: relative;
		margin-top: pxToVw(78px);
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.ExplorationDetail {
		margin-left: unset;
		top: pxToVwMobile(30px);
		padding: 0 pxToVwMobile(30px);
		&__headingText {
			font-size: pxToVwMobile(24px);
			line-height: pxToVwMobile(34px);
		}
		&__descriptionText {
			font-size: pxToVwMobile(16px);
			max-width: pxToVwMobile(290px);
			line-height: pxToVwMobile(26px);
		}
		&__seeLocationsButton {
			margin-top: pxToVwMobile(52px);
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint
}
