@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.Background {
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background: linear-gradient(180deg, #000000 0%, #142027 100%);

	&__backgroundImage {
		width: 100%;
		height: 100%;
		position: absolute;
		img {
			position: absolute;
		}
		&__smallMeteorTop {
			z-index: 1;
			left: pxToVw(68px);
			top: pxToVw(-110px);
			width: pxToVw(86px);
		}
		&__meteor5 {
			z-index: 1;
			right: pxToVw(272px);
			top: pxToVw(-70px);
			width: pxToVw(86px);
		}
		&__planet {
			right: 0;
			position: absolute;
			height: 100%;
			img {
				opacity: 0.3;
				position: relative;
				width: pxToVw(458px);
				filter: saturate(300%) brightness(.62) contrast(2.5) hue-rotate(-15deg) sepia(0.6);
			}
			&:before {
				z-index: 1;
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				background: linear-gradient(180deg, #000000 0%, #142027 100%);
				-webkit-mask: radial-gradient(circle at 95% 45%, rgba(0, 0, 0, 0) 45%, #0D1318 47%);
			}
			&:after {
				content: '';
				width: 120%;
				height: pxToVwMobile(60px);
				position: absolute;
				top: pxToVwMobile(40px);
				right: pxToVwMobile(-40px);
				transform: rotate(-20deg);
				background: linear-gradient(
					180deg,
					#0F1218 15%,
					rgba(15, 18, 24, 0.4) 35%,
					rgba(15, 18, 24, 0.2) 50%,
					rgba(0, 0, 0, 0) 100%
			);
			}
		}
		&__meteor6 {
			bottom: pxToVw(80px);
			height: pxToVw(184px);
		}
	}

	&__lines {
		&__mainLines {
			width: 100%;
			height: pxToVw(1px);
			position: absolute;
			top: pxToVw(410px);
			background: linear-gradient(
				to right,
				rgba(255,255,255,0.1) pxToVw(570px),
				rgba(255, 255, 255, 0.2) pxToVw(570px),
				rgba(255, 255, 255, 0.2) pxToVw(620px),
				rgba(255,255,255,0.1) pxToVw(620px)
			);
			z-index: 2;

			&::after {
				content: '';
				width: pxToVw(1px);
				height: pxToVw(538px);
				position: absolute;
				top: pxToVw(-181.5px);
				left: pxToVw(595px);
				background: linear-gradient(
					to bottom,
					rgba(255,255,255,0.1) pxToVw(156.5px),
					rgba(255, 255, 255, 0.2) pxToVw(156.5px),
					rgba(255, 255, 255, 0.2) pxToVw(206.5px),
					rgba(255,255,255,0.1) pxToVw(206.5px),
					transparent
				);
			}
		}

		&__verticalLine {
			width: pxToVw(1px);
			height: 140%;
			position: absolute;
			left: pxToVw(162px);
			top: pxToVw(-300px);
			background: linear-gradient(
				to bottom,
				transparent,
				rgba(255, 255, 255, 0.1) 20%,
				rgba(255, 255, 255, 0.1) 80%,
				transparent
				);
			z-index: 2;
		}

		&__bestSellerLines {
			width: pxToVw(1px);
			height: pxToVw(800px);
			position: absolute;
			right: pxToVw(132px);
			background: linear-gradient(
				to bottom,
				transparent,
				rgba(255,255,255,0.28) pxToVw(150px),
				rgba(255,255,255,0.1) pxToVw(400px),
				transparent
				);
			z-index: 2;

			&::before {
				content: 'BESTSELLER';
				position: absolute;
				top: pxToVw(100px);
				right: pxToVw(-24px);
				opacity: 0.59;
				transform: rotate(-90deg);

				color: #FFFFFF;
				font-size: pxToVw(12px);
				letter-spacing: pxToVw(3px);
				line-height: pxToVw(28px);
				@include exo-2-regular();
			}

		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Background {
		background: linear-gradient(180deg, #000000 0%, #142027 40%);

		&__backgroundImage {

			&__smallMeteorTop {
				left: pxToVwMobile(20px);
				top: pxToVwMobile(-60px);
				width: pxToVwMobile(40px);
			}
			&__meteor5 {
				display: block;
				width: pxToVwMobile(66px);
				right: pxToVwMobile(22px);
				top: pxToVwMobile(130px);
				z-index: 2;
			}
			&__planet {

				img {
					opacity: 0.3;
					position: relative;
					top: pxToVwMobile(318px);
					width: pxToVwMobile(260px);
					right: pxToVwMobile(-26px);
					filter: saturate(300%);
				}
				&:before {
					z-index: 2;
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					background: linear-gradient(180deg, #000000 0%, #142027 40%);
					-webkit-mask: radial-gradient(circle at 102% 59%, rgba(0, 0, 0, 0) 26%, #0D1318 27%);
				}
				&:after {
					z-index: 1;
					content: '';
					width: 100%;
					height: pxToVwMobile(160px);
					position: absolute;
					top: pxToVwMobile(390px);
					left: pxToVwMobile(20px);
					transform: rotate(-20deg);
					background: linear-gradient(
						to bottom,
						rgba(255,255,255,0.1) 20%,
						rgba(255, 255, 255, 0.2) 20%,
						rgba(255, 255, 255, 0.2) 21%,
						rgba(255,255,255,0.1) 21%,
						transparent
					);
					background: linear-gradient(180deg, #000000 0%, transparent 100%);
				}
			}
			&__meteor6 {
				bottom: pxToVwMobile(308px);
				height: pxToVwMobile(88px);
			}
		}

		&__lines {
			&__mainLines {
				width: 100%;
				height: pxToVwMobile(1px);
				position: absolute;
				top: pxToVwMobile(316px);
				background: linear-gradient(
					to right,
					rgba(255,255,255,0.1) pxToVwMobile(224px),
					rgba(255, 255, 255, 0.3) pxToVwMobile(224px),
					rgba(255, 255, 255, 0.3) pxToVwMobile(274px),
					rgba(255,255,255,0.1) pxToVwMobile(274px)
				);
				z-index: 2;

				&::after {
					content: '';
					width: pxToVwMobile(1px);
					height: pxToVwMobile(286px);
					position: absolute;
					top: pxToVwMobile(-181.5px);
					left: pxToVwMobile(247.5px);
					background: linear-gradient(
						to bottom,
						rgba(255,255,255,0.1) pxToVwMobile(156.5px),
						rgba(255, 255, 255, 0.3) pxToVwMobile(156.5px),
						rgba(255, 255, 255, 0.3) pxToVwMobile(206.5px),
						rgba(255,255,255,0.1) pxToVwMobile(206.5px),
						transparent
					);
				}
			}

			&__verticalLine {
				visibility: hidden;
			}

			&__bestSellerLines {
				width: pxToVwMobile(1px);
				height: pxToVwMobile(1000px);
				position: absolute;
				right: pxToVwMobile(30px);
				top: pxToVwMobile(-200px);
				background: linear-gradient(
					to bottom,
					transparent,
					rgba(255,255,255,1) pxToVwMobile(160px),
					rgba(255,255,255,1) pxToVwMobile(180px),
					rgba(255,255,255,0.28) pxToVwMobile(280px),
					rgba(255,255,255,0.1) pxToVwMobile(400px),
					transparent
					);
				z-index: 2;

				&::before {
					content: 'BESTSELLER';
					position: absolute;
					top: pxToVwMobile(372px);
					right: pxToVwMobile(-24px);
					opacity: 0.59;
					transform: rotate(-90deg);

					font-size: pxToVwMobile(10px);
					letter-spacing: pxToVwMobile(2.5px);
					line-height: pxToVwMobile(28px);
				}
			}
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint
}
