@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.ScrollIndicator {
	$width: pxToVw(270px);
	display: flex;
	width: $width;
	position: relative;
	height: pxToVw(340px);
	justify-content: center;
	@keyframes rotateOuterCircle {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	&__OuterCircle {
		top: 0;
		left: 0;
		display: flex;
		width: $width;
		height: $width;
		position: absolute;
		align-items: center;
		font-size: pxToVw(12px);
		justify-content: center;
		color: rgba(255, 255, 255, 0.6);
		@include exo-2-regular();
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			border-radius: 50%;
			background-size: contain;
			animation-duration: 16000ms;
			animation-timing-function: linear;
			animation-name: rotateOuterCircle;
			animation-iteration-count: infinite;
			background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50%' ry='50%' stroke='rgba(255,255,255,0.22)' stroke-width='4' stroke-dasharray='1 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
		}
		&:after {
			content: '';
			position: absolute;
			border-radius: 50%;
			align-items: center;
			width: pxToVw(160px);
			height: pxToVw(160px);
			box-sizing: border-box;
			justify-content: center;
			color: rgba(255, 255, 255, 0.6);
			border: pxToVw(1px) solid rgba(255,255,255,0.22);
		}
	}
	&:after {
		content: '';
		top: pxToVw(180px);
		position: absolute;
		width: pxToVw(1px);
		height: pxToVw(160px);
		background-color: rgba(255,255,255,0.47);
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.ScrollIndicator {
		$width: pxToVwMobile(136px);
		width: $width;
		height: pxToVwMobile(187px);
		&__OuterCircle {
			width: $width;
			height: $width;
			font-size: pxToVwMobile(7px);
			&:before {
				background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50%' ry='50%' stroke='rgba(255,255,255,0.15)' stroke-width='2' stroke-dasharray='1 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
			}
			&:after {
				width: pxToVwMobile(72px);
				height: pxToVwMobile(72px);
				border: pxToVwMobile(1px) solid rgba(255,255,255,0.22);
			}
		}
		&:after {
			content: '';
			position: absolute;
			top: pxToVwMobile(95px);
			width: pxToVwMobile(1px);
			height: pxToVwMobile(92px);
			background-color: rgba(255,255,255,0.47);
		}
	}
}
