@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.BestSellersDetail {
	width: 100%;
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__headingText {
		margin-top: pxToVw(64px);
		color: #ffffff;
		font-size: pxToVw(40px);
		line-height: pxToVw(54px);
		text-transform: uppercase;
		text-align: center;
		@include exo-2-bold();
		span {
			color: #F3BF00;
		}
	}

	&__content {
		display: flex;
		align-items: flex-end;
		width: 100%;
		justify-content: space-between;
		margin-top: pxToVw(90px);

		&__menu {
			display: flex;
			flex-direction: column;
			align-items: center;

			&:nth-child(1) {
				margin-left: pxToVw(74px);

				img {
					width: pxToVw(360px);
				}
			}

			&:nth-child(2) {
				transform: translateY(pxToVw(120px));

				img {
					width: pxToVw(376px);
				}
			}

			&:nth-child(3) {
				margin-right: pxToVw(128px);

				.BestSellersDetail__content__menu__image__shadow {
					width: 80%;
					left: 10%;
					bottom: pxToVw(-32px);
				}

				img {
					width: pxToVw(270px);
				}
			}

			&__image {
				position: relative;

				&__shadow {
					background: radial-gradient(#000 40%, transparent 65%);
					width: 100%;
					height: pxToVw(200px);
					position: absolute;
					bottom: pxToVw(-40px);
					left: 0%;
				}

				img {
					position: relative;
				}

			}

			&__text {
				margin-top: pxToVw(40px);
				color: #FFFFFF;
				font-size: pxToVw(12px);
				letter-spacing: pxToVw(2px);
				line-height: pxToVw(20px);
				text-transform: uppercase;
				@include exo-2-light();
			}
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.BestSellersDetail {

		&__headingText {
			font-size: pxToVwMobile(24px);
			line-height: pxToVwMobile(34px);
			max-width: pxToVwMobile(280px);
			@include exo-2-black();
		}

		&__content {
			align-items: center;
			flex-direction: column;
			justify-content: flex-start;
			margin-top: pxToVwMobile(122px);

			&__menu {
				margin-bottom: pxToVwMobile(60px);

				&:nth-child(1) {
					margin-left: 0;

					img {
						width: pxToVwMobile(250px);
					}
				}

				&:nth-child(2) {
					transform: none;

					img {
						width: pxToVwMobile(258px);
					}
				}

				&:nth-child(3) {
					margin-right: 0;

					.BestSellersDetail__content__menu__image__shadow {
						width: 80%;
						left: 10%;
						bottom: pxToVwMobile(-32px);
					}

					img {
						width: pxToVwMobile(164px);
					}
				}

				&__image {
					&__shadow {
						background: radial-gradient(#000 5%, transparent 65%);
						height: pxToVwMobile(100px);
						bottom: pxToVwMobile(-20px);
					}
				}

				&__text {
					margin-top: pxToVwMobile(16px);
					font-size: pxToVwMobile(12px);
					letter-spacing: pxToVwMobile(2px);
					line-height: pxToVwMobile(20px);
				}
			}
		}
	}
}
