@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.Background {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background: linear-gradient(180deg, #152027 0%, #121141 100%);
	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		background-size: pxToVw(108px) pxToVw(108px);
		background-position: pxToVw(54px) pxToVw(45px);
		background-image:
			linear-gradient(to right, rgba(255, 255, 255, 0.04) pxToVw(1px), transparent pxToVw(1px)),
			linear-gradient(to bottom, rgba(255, 255, 255, 0.04) pxToVw(1px), transparent pxToVw(1px));
	}
	&__satelliteContainer {
		bottom: 0;
		width: 100%;
		display: flex;
		overflow: hidden;
		position: absolute;
		justify-content: flex-end;
		img {
			right: 0;
			bottom: 0;
			top: pxToVw(8px);
			position: relative;
			height: pxToVw(690px);
		}
	}
	&__gradientOverlay {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		&:before {
			bottom: 0;
			content: '';
			width: 100%;
			position: absolute;
			height: pxToVw(308px);
			background: linear-gradient(0deg, #121141 9%, rgba(23, 31, 48, 0) 100%);
		}
		&:after {
			top: 0;
			content: '';
			width: 100%;
			position: absolute;
			height: pxToVw(308px);
			background: linear-gradient(180deg, #152027 8%, rgba(18, 17, 65, 0) 100%);
		}
	}
	&__smallMeteor {
		top: pxToVw(109px);
		position: absolute;
		width: pxToVw(86px);
		left: pxToVw(121px);
	}
	&__largeMeteor {
		left: 0;
		top: pxToVw(185px);
		position: absolute;
		width: pxToVw(156px);
	}
	&__gridBackground {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		&:before {
			content: '';
			top: pxToVw(153px);
			left: pxToVw(80px);
			position: absolute;
			height: pxToVw(1px);
			width: pxToVw(952px);
			background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.10) 25%, rgba(255,255,255,0.10) 85%, rgba(255,255,255,0) 100%);
		}
		&:after {
			z-index: 1;
			content: '';
			top: pxToVw(18px);
			position: absolute;
			width: pxToVw(1px);
			left: pxToVw(270px);
			height: pxToVw(637px);
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.10) 25%, rgba(255,255,255,0.10) 80%, rgba(255,255,255,0) 100%);
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Background {
		background: linear-gradient(180deg, #152027 40%, #121141 100%);
		&:before {
			z-index: 2;
			height: 150%;
			top: pxToVwMobile(-262px);
			background-position: pxToVwMobile(28px) 0, 0 0;
			background-size: pxToVwMobile(105px) pxToVwMobile(105px);
			background-image:
				linear-gradient(to right, rgba(255, 255, 255, 0.1) pxToVw(1px), transparent pxToVw(1px)),
				linear-gradient(to bottom, rgba(255, 255, 255, 0.1) pxToVw(1px), transparent pxToVw(1px));
		}
		&__satelliteContainer {
			overflow: hidden;
			align-items: flex-end;
			bottom: pxToVwMobile(-18px);
			height: pxToVwMobile(400px);
			img {
				bottom: pxToVwMobile(-28px);
				height: pxToVwMobile(350px);
			}
		}
		&__gradientOverlay {
			&:before, &:after {
				height: pxToVwMobile(128px);
			}
			&:before {
				background: linear-gradient(0deg, #121141 0%, rgba(23, 31, 48, 0) 100%);
			}
		}
		&__smallMeteor {
			top: pxToVwMobile(43px);
			left: pxToVwMobile(113px);
			width: pxToVwMobile(66px);
		}
		&__largeMeteor {
			top: pxToVwMobile(147px);
			left: pxToVwMobile(-20px);
			width: pxToVwMobile(120px);
		}
		&__gridBackground {
			display: none;
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint
}
