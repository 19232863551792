@import "src/styles/typographies.module";
@import 'src/styles/others.module';

.PartnershipMoreInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: pxToVw(86px);

	&__text {
		max-width: pxToVw(596px);
		margin-bottom: pxToVw(90px);
		color: #FFFFFF;
		font-size: pxToVw(18px);
		line-height: pxToVw(28px);
		text-align: center;
		@include exo-2-light;
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.PartnershipMoreInfo {
		margin-top: pxToVwMobile(55px);

		&__text {
			max-width: pxToVwMobile(316px);
			font-size: pxToVwMobile(16px);
			line-height: pxToVwMobile(28px);
			margin-bottom: pxToVwMobile(60px);
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint
}
