@import "src/styles/others.module";

.Hero__background {
	top: 0;
	width: 100%;
	position: absolute;
	height: pxToVw(780px);
	&__backgroundImage {
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0.55;
		overflow: hidden;
		position: absolute;
		&__planet {
			top: 0;
			width: 100%;
			position: absolute;
			height: pxToVw(814px);
			filter: saturate(200%) brightness(90%);
		}
		&__planetTexture {
			top: 0;
			opacity: 0.5;
			position: absolute;
			left: pxToVw(58px);
			height: pxToVw(605px);
			width: pxToVw(1196px);
		}
	}
	&__filter {
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		background: rgb(0,0,0);
		background: radial-gradient(circle at 50% 34%, rgba(0,0,0,0) 42%, rgba(0,0,0,1) 47%);
		&__gridLine {
			width: 100%;
			opacity: 0.85;
			position: relative;
		}
		&__gradientFilterOverlay {
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			position: absolute;
			background:
				linear-gradient(90deg, rgba(0, 0, 0, 0) pxToVw(1150px), #000000 100%),
				linear-gradient(110deg, black 23%, rgba(0,0,0,0) pxToVw(630px)),
				linear-gradient(180deg, rgba(0, 0, 0, 0) pxToVw(640px), #000000 100%);
			&:before {
				content: '';
				height: 100%;
				position: absolute;
				width: pxToVw(560px);
				transform: rotate(-10deg) translateX(pxToVw(-56px)) translateY(pxToVw(-48px));
				background: linear-gradient(270deg, rgba(0,0,0,0) 0%, #000000 100%);
			}
		}
		&:after {
			top: 0;
			left: 0;
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			background-position: pxToVw(2px);
			background-size: pxToVw(14px) pxToVw(14px);
			background-image: radial-gradient(rgba(255, 255, 255, 0.05) pxToVw(1px), transparent 0);
		}
	}
	&__meteor {
		img {
			position: absolute;
		}

		img:nth-child(1) {
			z-index: 2;
			top: pxToVw(55px);
			width: pxToVw(50px);
			right: pxToVw(212px);
			filter: contrast(1.08);
		}
		img:nth-child(2) {
			top: pxToVw(90px);
			right: pxToVw(90px);
			width: pxToVw(108px);
			filter: brightness(0.8) contrast(1.08);
		}
		img:nth-child(3) {
			z-index: 1;
			opacity: 0.2;
			top: pxToVw(465px);
			left: pxToVw(122px);
			width: pxToVw(130px);
			bottom: pxToVw(200px);
		}
	}
}
