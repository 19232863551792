@import 'src/styles/others.module';

.ZoomButton {
	width: pxToVw(30px);
	height: pxToVw(30px);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	border: pxToVw(0.6px) solid rgba(255,255,255,0.5);
	margin-right: pxToVw(110px);
	cursor: pointer;

	&__in {
		
		&::before {
			content: '';
			width: pxToVw(12px);
			height: pxToVw(1px);
			background-color: #FFCF49;
		}

		&::after {
			content: '';
			width: pxToVw(1px);
			height: pxToVw(12px);
			background-color: #FFCF49;
			position: absolute;
		}
	}

	&__out {
		margin-top: pxToVw(16px);

		&::before {
			content: '';
			width: pxToVw(12px);
			height: pxToVw(1px);
			background-color: #FFCF49;
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.ZoomButton {
		display: none;
		// width: pxToVwMobile(30px);
		// height: pxToVwMobile(30px);
		// display: flex;
		// justify-content: center;
		// align-items: center;
		// border-radius: 100%;
		// border: pxToVw(0.6px) solid rgba(255,255,255,0.5);
		// margin-right: pxToVw(110px);
		// cursor: pointer;
	
		// &__in {
			
		// 	&::before {
		// 		content: '';
		// 		width: pxToVwMobile(12px);
		// 		height: pxToVwMobile(1px);
		// 		background-color: #FFCF49;
		// 	}
	
		// 	&::after {
		// 		content: '';
		// 		width: pxToVwMobile(1px);
		// 		height: pxToVwMobile(12px);
		// 		background-color: #FFCF49;
		// 		position: absolute;
		// 	}
		// }
	
		// &__out {
		// 	margin-top: pxToVwMobile(16px);
	
		// 	&::before {
		// 		content: '';
		// 		width: pxToVwMobile(12px);
		// 		height: pxToVwMobile(1px);
		// 		background-color: #FFCF49;
		// 	}
		// }
	}
}