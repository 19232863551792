@import "src/styles/typographies.module";
@import "src/styles/others.module";
@import "src/styles/colors.module";

.Ticker {
	color: $milk;
	display: flex;
	cursor: default;
	align-items: center;
	flex-direction: row;
	width: pxToVw(360px);
	padding: pxToVw(16px);
	font-size: pxToVw(12px);
	line-height: pxToVw(18px);
	background-color: #494bd7;
	justify-content: space-between;
	letter-spacing: pxToVw(1.67px);
	@include exo-2-semibold;
	&__innerContainer {
		flex: 1;
	}
	&__closeButton {
		border: none;
		display: flex;
		outline: none;
		cursor: pointer;
		align-items: center;
		width: pxToVw(32px);
		height: pxToVw(32px);
		justify-content: center;
		background-color: transparent;
		&__icon {
			color: $milk;
			pointer-events: none;
			font-size: pxToVw(14px);
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Ticker {
		width: pxToVwMobile(290px);
		padding: pxToVwMobile(12px);
		font-size: pxToVwMobile(12px);
		line-height: pxToVwMobile(18px);
		letter-spacing: pxToVwMobile(1.67px);
		&__closeButton {
			width: pxToVwMobile(24px);
			height: pxToVwMobile(24px);
			&__icon {
				font-size: pxToVwMobile(14px);
			}
		}
	}
}
