@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.Achievements {
	z-index: 2;
	width: 100%;
	display: flex;
	top: pxToVw(460px);
	position: absolute;
	align-items: flex-end;
	flex-direction: column;
	&__headerTitle {
		right: 0;
		width: pxToVw(735px);
		font-size: pxToVw(12px);
		line-height: pxToVw(28px);
		letter-spacing: pxToVw(3px);
		margin-bottom: pxToVw(56px);
		color: rgba(255, 255, 255, 0.6);
		@include exo-2-regular();
	}
	&__eachAchievement {
		right: 0;
		width: 100%;
		display: flex;
		position: relative;
		align-items: flex-end;
		flex-direction: column;
		&__innerContainer {
			width: pxToVw(735px);
			padding-top: pxToVw(14px);
			padding-bottom: pxToVw(40px);
			&__achievementIndex {
				font-size: pxToVw(12px);
				margin-right: pxToVw(40px);
				color: rgba(255, 255, 255, 0.5);
				@include exo-2-regular();
			}
			span, p {
				color: #ffffff;
				display: inline-block;
				font-size: pxToVw(24px);
				line-height: pxToVw(54px);
				@include exo-2-light();
			}
			&__highlightText {
				color: #00FFC6 !important;
				margin-right: pxToVw(8px);
			}
		}
		&:before {
			top: 0;
			content: '';
			position: absolute;
			height: pxToVw(1px);
			width: pxToVw(735px);
			background: rgba(255,255,255,0.5);
		}
		&:after {
			top: 0;
			width: 100%;
			content: '';
			position: absolute;
			height: pxToVw(1px);
			background: rgba(255,255,255,0.1);
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Achievements {
		width: 100%;
		right: unset;
		box-sizing: border-box;
		align-items: flex-start;
		top: pxToVwMobile(466px);
		padding: 0 pxToVwMobile(30px);
		&__headerTitle {
			font-size: pxToVwMobile(12px);
			line-height: pxToVwMobile(28px);
			margin-bottom: pxToVwMobile(30px);
		}
		&__eachAchievement {
			&__innerContainer {
				width: 100%;
				display: flex;
				flex-direction: row;
				padding-top: pxToVwMobile(32px);
				padding-bottom: pxToVwMobile(30px);
				&__achievementIndex {
					white-space: nowrap;
					margin-right: pxToVwMobile(46px);
					font-size: pxToVwMobile(12px) !important;
					line-height: pxToVwMobile(28px) !important;
				}
				span, p {
					font-size: pxToVwMobile(16px);
					line-height: pxToVwMobile(26px);
				}
			}
			&:before {
				width: 100%;
			}
			&:after {
				content: none;
			}
		}
	}
}
