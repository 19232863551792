@import 'src/styles/others.module';
@import 'src/styles/typographies.module';

.OutletDetails {
	&__header {
		z-index: 1;
		display: none;
		position: relative;
		top: pxToVw(236px);
		left: pxToVw(164px);
		-webkit-user-select: none; /* Safari */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */
		&__button {
			display: flex;
			align-items: center;
			&__navigationButton {
				padding: 0;
				cursor: pointer;
				border-radius: 50%;
				width: pxToVw(50px);
				height: pxToVw(50px);
				box-sizing: border-box;
				background-color: transparent;
				border: pxToVw(1px) solid rgba(255, 255, 255, 0.5);
				&:last-child {
					display: none;
				}
				svg {
					color: #FFFFFF;
					font-size: pxToVw(20px);
				}
			}
			&__label {
				margin-left: pxToVw(20px);
				opacity: 0.59;
				color: #FFFFFF;
				font-size: pxToVw(12px);
				letter-spacing: pxToVw(3px);
				line-height: pxToVw(28px);
				@include exo-2-regular;

				-webkit-user-select: none; /* Safari */
				-moz-user-select: none; /* Firefox */
				-ms-user-select: none; /* IE10+/Edge */
				user-select: none; /* Standard */
			}
		}
	}
	&__content {
		z-index: 1;
		display: none;
		position: relative;
		top: pxToVw(230px);
		left: pxToVw(164px);
		pointer-events: none;
		&__title {
			width: pxToVw(340px);
			text-transform: uppercase;
			color: #FFFFFF;
			font-size: pxToVw(40px);
			letter-spacing: 0;
			line-height: pxToVw(54px);
			@include exo-2-bold;
			span {
				color: #FAC402;
			}
		}
		&__address {
			width: pxToVw(260px);
			margin-top: pxToVw(54px);
			color: #FFFFFF;
			font-size: pxToVw(18px);
			line-height: pxToVw(28px);
			@include exo-2-light;
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.OutletDetails {
		&__header {
			opacity: 0;
			display: none;
			position: relative;
			top: pxToVwMobile(100px);
			left: pxToVwMobile(28px);
			-webkit-user-select: none; /* Safari */
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* IE10+/Edge */
			user-select: none; /* Standard */
			&__button {
				&__navigationButton {
					padding: 0;
					cursor: pointer;
					border-radius: 50%;
					width: pxToVwMobile(26px);
					height: pxToVwMobile(26px);
					box-sizing: border-box;
					background-color: transparent;
					border: pxToVwMobile(1px) solid rgba(255, 255, 255, 0.5);
					&:last-child {
						display: none;
					}
					svg {
						color: #FFFFFF;
						font-size: pxToVwMobile(10px);
					}
				}

				&__index {
					margin-left: pxToVwMobile(10px);
					opacity: 0.5;
					color: #FFFFFF;
					font-size: pxToVwMobile(12px);
					letter-spacing: pxToVwMobile(3px);
					line-height: pxToVwMobile(28px);
					@include exo-2-regular;
				}

				&__location {
					color: #FAC402;
					text-transform: uppercase;
					font-size: pxToVwMobile(12px);
					margin-left: pxToVwMobile(10px);
					@include exo-2-bold;
				}
			}
		}
		&__content {
			left: 0;
			bottom: 0;
			top: unset;
			opacity: 0;
			width: 100%;
			color: white;
			display: none;
			position: absolute;
			box-sizing: border-box;
			flex-direction: column;
			padding: 0 pxToVwMobile(50px);
			&__title {
				margin: 0;
				color: #FFFFFF;
				letter-spacing: 0;
				text-transform: uppercase;
				width: pxToVwMobile(204px);
				font-size: pxToVwMobile(24px);
				line-height: pxToVwMobile(34px);
				@include exo-2-bold;
				span {
					color: #FAC402;
				}
			}
			&__address {
				color: #FFFFFF;
				width: pxToVwMobile(270px);
				font-size: pxToVwMobile(12px);
				margin-top: pxToVwMobile(24px);
				line-height: pxToVwMobile(22px);
				@include exo-2-light;
			}
			&__divider {
				height: pxToVwMobile(1px);
				max-width: pxToVwMobile(314px);
				margin-top: pxToVwMobile(32px);
				background-color: rgba(255,255,255,0.47);
			}
		}
	}
}
