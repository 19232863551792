@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.Background {
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background: linear-gradient(180deg, rgba(0,0,0,1) 0%, #152027 42%);
	img {
		position: absolute;
	}
	&__smallMeteorTop {
		z-index: 2;
		left: pxToVw(68px);
		top: pxToVw(-118px);
		width: pxToVw(86px);
	}
	&__largeMeteorTop {
		left: 0;
		z-index: 2;
		top: pxToVw(16px);
		height: pxToVw(184px);
	}
	&__planet {
		top: 0;
		right: 0;
		height: 100%;
		overflow: hidden;
		position: absolute;
		img {
			opacity: 0.3;
			top: pxToVw(-16px);
			position: relative;
			width: pxToVw(458px);
			filter: saturate(300%) brightness(.62) contrast(2.5) hue-rotate(-15deg) sepia(0.6);
		}
		&:before {
			z-index: 2;
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			background: linear-gradient(180deg, rgba(0,0,0,1) 0%, #142027 42%);
			-webkit-mask: radial-gradient(
					circle at pxToVw(440px) pxToVw(414px),
					rgba(40, 58, 63, 0) pxToVw(320px),
					#0D1318 pxToVw(330px)
			);
		}
		&:after {
			top: 0;
			left: 0;
			z-index: 1;
			content: '';
			width: 150%;
			position: absolute;
			height: pxToVw(256px);
			transform: rotateZ(-15deg) translateY(pxToVw(90px));
			background: linear-gradient(
					180deg,
					#0F1218 15%,
					rgba(15, 18, 24, 0.4) 35%,
					rgba(15, 18, 24, 0.2) 50%,
					rgba(0, 0, 0, 0) 100%
			);
		}
	}
	&__moonSurface {
		left: 0;
		overflow: hidden;
		top: pxToVw(660px);
		position: absolute;
		width: pxToVw(635px);
		height: pxToVw(600px);
		img {
			bottom: 0;
			width: 100%;
			opacity: 0.16;
		}
	}
	&__gridBackground {
		position: relative;
		height: pxToVw(940px);
		&__verticalMainLine {
			z-index: 2;
			height: 100%;
			position: absolute;
			width: pxToVw(1px);
			top: pxToVw(-167px);
			right: pxToVw(132px);
			background: linear-gradient(
					180deg,
					rgba(255,255,255,0) 12%, rgba(255,255,255,0.1) 20%, rgba(255,255,255,0.33) 25%, rgba(255,255,255,0.33) 37%, rgba(255,255,255,0.1) 45%, rgba(255,255,255,0.05) 75%, rgba(255,255,255,0) 100%
			);
			&:before {
				right: 0;
				content: 'ABOUT';
				top: pxToVw(230px);
				position: absolute;
				font-size: pxToVw(12px);
				line-height: pxToVw(28px);
				transform: rotateZ(-90deg);
				letter-spacing: pxToVw(3px);
				color: rgba(255, 255, 255, 0.6);
				@include exo-2-regular();
			}
		}
		&__verticalLine {
			width: 100%;
			height: 100%;
			position: absolute;
			&:before {
				content: '';
				top: pxToVw(235px);
				position: absolute;
				width: pxToVw(1px);
				right: pxToVw(735px);
				height: pxToVw(538px);
				background: linear-gradient(
						180deg,
						rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%
				);
			}
			&:after {
				z-index: 1;
				content: '';
				position: absolute;
				width: pxToVw(1px);
				top: pxToVw(-260px);
				left: pxToVw(164px);
				height: pxToVw(1210px);
				background: linear-gradient(
						180deg,
						rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 18%, rgba(255,255,255,0.1) 90%, rgba(255,255,255,0) 100%
				);
			}
		}
		&__horizontalLine {
			left: 0;
			z-index: 2;
			width: 100%;
			top: pxToVw(418px);
			position: absolute;
			height: pxToVw(1px);
			background: rgba(255,255,255,0.1);
			&:before, &:after {
				content: '';
				position: absolute;
				left: pxToVw(548px);
				width: pxToVw(50px);
				height: pxToVw(1px);
				background: rgba(255, 255, 255, 0.4);
			}
			&:before {
				top: 0;
			}
			&:after {
				transform: rotateZ(90deg);
			}
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Background {
		background: linear-gradient(180deg, #000000 0%, #152027 42%);
		&__planet {
			img {
				top: pxToVwMobile(-24px);
				width: pxToVwMobile(220px);
			}
			&:before {
				background: linear-gradient(180deg, #000000 0%, #142027 42%);
				-webkit-mask: radial-gradient(
						circle at pxToVwMobile(210px) pxToVwMobile(185px),
						rgba(40, 58, 63, 0) pxToVwMobile(150px),
						#0D1318 pxToVwMobile(160px)
				);
			}
			&:after {
				height: pxToVwMobile(128px);
				transform: rotateZ(-15deg) translateY(pxToVwMobile(35px));
				background: linear-gradient(
						180deg,
						#0F1218 17%,
						rgba(15, 18, 24, 0.4) 35%,
						rgba(15, 18, 24, 0.2) 50%,
						rgba(0, 0, 0, 0) 100%
				);
			}
		}
		&__smallMeteorTop {
			left: pxToVwMobile(37px);
			width: pxToVwMobile(41px);
			top: pxToVwMobile(-196px);
		}
		&__largeMeteorTop {
			height: auto;
			left: pxToVwMobile(-38px);
			top: pxToVwMobile(-139px);
			width: pxToVwMobile(81px);
		}
		&__moonSurface {
			z-index: 2;
			top: pxToVwMobile(638px);
			left: pxToVwMobile(-410px);
			width: pxToVwMobile(635px);
			height: pxToVwMobile(598px);
		}
		&__gridBackground {
			height: pxToVwMobile(940px);
			&__verticalMainLine {
				width: pxToVwMobile(1px);
				right: pxToVwMobile(30px);
				top: pxToVwMobile(-300px);
				background: linear-gradient(
						180deg,
						rgba(255,255,255,0) 10%, rgba(255,255,255,0.1) 15%, rgba(255,255,255,0.6) 25%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0.05) 85%, rgba(255,255,255,0) 100%
				);
				&:before {
					top: pxToVwMobile(390px);
					font-size: pxToVwMobile(10px);
					line-height: pxToVwMobile(28px);
					letter-spacing: pxToVwMobile(2.5px);
				}
			}
			&__verticalLine {
				&:before {
					z-index: 2;
					top: pxToVwMobile(156px);
					width: pxToVwMobile(1px);
					right: pxToVwMobile(125px);
					height: pxToVwMobile(286px);
					background: linear-gradient(
							180deg,
							rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 90%
					);
				}
				&:after {
					display: none;
				}
			}
			&__horizontalLine {
				top: pxToVwMobile(324px);
				height: pxToVwMobile(1px);
				&:before, &:after {
					height: pxToVwMobile(1px);
					width: pxToVwMobile(50px);
					left: pxToVwMobile(224px);
					background: rgba(255, 255, 255, 0.15);
				}
			}
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint;
}
