@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.FlavorsDetail {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	z-index: 2;

	&__title {
		margin: 0 pxToVw(20px);
		color: #FFFFFF;
		font-size: pxToVw(40px);
		line-height: pxToVw(54px);
		text-transform: uppercase;
		@include exo-2-bold();
		z-index: 2;

		span {
			color: #F7C200;
		}
	}

	&__button {
		margin-top: pxToVw(120px);
		z-index: 3;
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.FlavorsDetail {

		&__title {
			margin: pxToVwMobile(116px) 0 pxToVwMobile(60px) 0;
			font-size: pxToVwMobile(24px);
			line-height: pxToVwMobile(34px);
			@include exo-2-black();
		}

		&__button {
			margin-top: pxToVwMobile(62px);
			z-index: 3;
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint
}
