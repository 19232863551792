@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.TextField {
    display: flex;
    flex-direction: column;

	&__label {
		display: flex;
		align-items: baseline;
		margin-bottom: pxToVw(20px);

		&__name {
			color: #00FFC6;
			font-size: pxToVw(24px);
			line-height: pxToVw(54px);
			@include exo-2-light;
		}

		&__optional {
			margin-left: pxToVw(20px);
			letter-spacing: pxToVw(3px);
  			line-height: pxToVw(28px);
			font-size: pxToVw(12px);
			color: #FFFFFF;
			opacity: 0.59;
			@include exo-2-regular;
		}
	}
  &__field {
    outline: none;
	  border-style: solid;
    width: pxToVw(609px);
    box-sizing: border-box;
    padding: 0 pxToVw(28px);
    background: transparent;
    border-width: pxToVw(1px);
	  transition-duration: 350ms;
    border-radius: pxToVw(32px);
	  border-color: rgba(255, 255, 255, 0.6);

    color: #FFFFFF;
    font-size: pxToVw(12px);
    letter-spacing: pxToVw(3px);
    line-height: pxToVw(28px);
    @include exo-2-medium;
	  &::-webkit-input-placeholder {
		  transition-duration: 350ms;
	  }
	  &::-moz-placeholder {
		  transition-duration: 350ms;
	  }
	  &:-ms-input-placeholder {
		  transition-duration: 350ms;
	  }
	  &__filled {
		  border-color: rgba(255, 255, 255, 1);
	  }

    &__input {
      height: pxToVw(66px);
      text-transform: uppercase;
    }

    &__textArea {
      height: pxToVw(277px);
      padding-top: pxToVw(20px);
      padding-bottom: pxToVw(20px);
      resize: none;

      &::-webkit-input-placeholder {
          text-transform: uppercase;
      }

      &::-moz-placeholder {
          text-transform: uppercase;
      }

    }

    &:focus {
	    border-color: rgba(255, 255, 255, 1);
	    &::-webkit-input-placeholder {
		    opacity: 0;
	    }
	    &::-moz-placeholder {
		    opacity: 0;
	    }
	    &:-ms-input-placeholder {
		    opacity: 0;
	    }
    }
  }
}

@media only screen and (max-width: $mobileBreakPoint) {
	.TextField {
		&__label {
			margin-bottom: pxToVwMobile(16px);

			&__name {
				font-size: pxToVwMobile(16px);
				line-height: pxToVwMobile(54px);
			}

			&__optional {
				margin-left: pxToVwMobile(20px);
				letter-spacing: pxToVwMobile(3px);
				line-height: pxToVwMobile(28px);
				font-size: pxToVwMobile(12px);
			}
		}

		&__field {
			width: pxToVwMobile(319px);
			padding: 0 pxToVwMobile(14px) 0 pxToVwMobile(18px);
			border-radius: pxToVwMobile(32px);

			font-size: pxToVwMobile(12px);
			letter-spacing: pxToVwMobile(3px);
			line-height: pxToVwMobile(28px);

			&__input {
				height: pxToVwMobile(66px);
			}

			&__textArea {
				height: pxToVwMobile(277px);
				padding-top: pxToVwMobile(20px);
				padding-bottom: pxToVwMobile(20px);
			}
		}
	}
}
