@import 'src/styles/others.module';

.PartnershipDetails {
	width: 100%;
	height: pxToVw(1960px);
	position: relative;
}

@media only screen and (max-width: $mobileBreakPoint) {
	.PartnershipDetails {
		height: pxToVwMobile(1772px);
	}
}
