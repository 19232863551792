@import 'src/styles/others.module';

.Hero {
	width: 100%;
	position: relative;
	height: pxToVw(883px);
	background-color: #000000;
	&__contentContainer {
		top: 0;
		left: 0;
		right: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		margin: auto;
		position: absolute;
		box-sizing: border-box;
		padding-top: pxToVw(120px);
		padding-left: pxToVw(164px);
		&__scrollIndicator {
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			margin: auto;
			position: absolute !important;
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Hero {
		height: pxToVwMobile(824px);
		&__contentContainer {
			padding-left: 0;
			max-width: unset;
			padding-top: pxToVwMobile(70px);
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint
}
