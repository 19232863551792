@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.Button {
	&__outer {
		display: inline-block;
	}
	&__inner {
		outline: none;
		cursor: pointer;
		overflow: hidden;
		position: relative;
		border-style: solid;
		height: pxToVw(64px);
		box-sizing: border-box;
		padding: 0 pxToVw(28px);
		border-width: pxToVw(1px);
		border-radius: pxToVw(32px);
		background-color: transparent;
		border-color: rgba(255,255,255,0.6);
		&__hover {
			border: none;
		}
		&__hover & {
			&__label {
				color: #000000;
			}
		}
		&__innerCircle {
			top: 100%;
			left: -25%;
			width: 150%;
			height: 200%;
			position: absolute;
			border-radius: 50%;
			background-color: #FAC402;
		}
		&__label {
			z-index: 1;
			display: block;
			color: #FAC402;
			position: relative;
			white-space: nowrap;
			font-size: pxToVw(12px);
			@include exo-2-semibold();
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Button {
		&__inner {
			height: pxToVwMobile(48px);
			padding: 0 pxToVwMobile(20px);
			border-radius: pxToVwMobile(24px);
			&__label {
				font-size: pxToVwMobile(12px);
				letter-spacing: pxToVwMobile(1.8px);
			}
		}
	}
}
