@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.CustomCursor {
	top: 0;
	opacity: 0;
	z-index: 3;
	display: flex;
	position: absolute;
	width: pxToVw(164px);
	pointer-events: none;
	justify-content: center;
	transition: opacity 200ms linear;
	&__circle {
		display: flex;
		border-radius: 50%;
		align-items: center;
		width: pxToVw(95px);
		height: pxToVw(95px);
		justify-content: center;
		border: solid pxToVw(1px) #fff;

		color: #FFFFFF;
		font-size: pxToVw(12px);
		letter-spacing: pxToVw(2px);
		@include exo-2-regular;

		user-select: none; /* supported by Chrome and Opera */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */

		&:before, &:after {
			top: 50%;
			content: '';
			width: pxToVw(24px);
			position: absolute;
			border-bottom: solid pxToVw(1px) #fff;
		}
		&:before {
			left: 0;
		}
		&:after {
			right: 0;
		}
	}
	&__visible {
		opacity: 1;
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.CustomCursor {
		display: none !important;
	}
}
