@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.Gallery {
	bottom: 0;
	z-index: 3;
	width: 100%;
	display: flex;
	overflow: hidden;
	position: absolute;
	&__headerText {
		display: flex;
		position: relative;
		flex-direction: row;
		left: pxToVw(164px);
		font-size: pxToVw(12px);
		line-height: pxToVw(28px);
		letter-spacing: pxToVw(3px);
		color: rgba(255, 255, 255, 0.6);
		@include exo-2-regular();
	}
	&__contentSlider {
		display: flex;
		position: relative;
		flex-direction: row;
		margin-top: pxToVw(60px);
		cursor: all-scroll !important;
		&:before {
			content: '';
			position: relative;
			width: pxToVw(164px);
		}
		&:after {
			content: '';
			position: relative;
			width: pxToVw(24px);
		}
	}
	&__eachImageItem {
		width: pxToVw(400px);
		&:not(:last-child) {
			margin-right: pxToVw(10px);
		}
		img {
			width: 100%;
		}
	}
	&__eachImageLabel {
		display: block;
		font-size: pxToVw(12px);
		margin-top: pxToVw(28px);
		line-height: pxToVw(21px);
		letter-spacing: pxToVw(3px);
		color: rgba(255, 255, 255, 0.5);
		@include exo-2-regular();
		p {
			color: #ffffff;
			display: inline-block;
			margin-left: pxToVw(12px);
			letter-spacing: pxToVw(2px);
			@include exo-2-bold();
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Gallery {
		&__headerText {
			left: pxToVwMobile(30px);
			font-size: pxToVwMobile(12px);
			line-height: pxToVwMobile(28px);
		}
		&__contentSlider {
			margin-top: pxToVwMobile(48px);
			&:before, &:after {
				width: pxToVwMobile(30px);
			}
		}
		&__eachImageItem {
			width: pxToVwMobile(256px);
			&:not(:last-child) {
				margin-right: pxToVwMobile(20px);
			}
		}
		&__eachImageLabel {
			font-size: pxToVwMobile(12px);
			margin-top: pxToVwMobile(28px);
			line-height: pxToVwMobile(21px);
			p {
				margin-left: pxToVwMobile(12px);
			}
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint
}
