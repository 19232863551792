@import 'src/styles/others.module';

.Background {
	width: 100%;
	height: 100%;
	position: absolute;
	background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(11,18,22,0.57) pxToVw(74px), #121141 pxToVw(700px), #121141 pxToVw(954px));
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Background {
		background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(11,18,22,0.57) pxToVwMobile(74px), #121141 pxToVwMobile(600px), #121141 pxToVwMobile(800px));
	}
}