@import 'src/styles/others.module';
@import 'src/styles/typographies.module';

.OutletLocationList {
	z-index: 1;
	position: absolute;
	width: pxToVw(490px);
	box-sizing: border-box;
	padding-top: pxToVw(274px);
	padding-left: pxToVw(164px);
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	&__title {
		margin-top: 0;
		font-size: pxToVw(12px);
		line-height: pxToVw(28px);
		letter-spacing: pxToVw(3px);
		margin-bottom: pxToVw(16px);
		color: rgba(255, 255, 255, 0.6);
		@include exo-2-regular;
	}
	&__list {
		display: flex;
		overflow: hidden;
		width: pxToVw(324px);
		height: pxToVw(330px);
		flex-direction: column;
		-webkit-user-select: none; /* Safari */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */

		&__item {
			display: flex;
			align-items: center;
			width: 100%;
			height: pxToVw(54px);
			border-top: pxToVw(1px) solid rgba(255,255,255,0.47);

			&__number {
				opacity: 0.5;
				color: #FFFFFF;
				font-size: pxToVw(12px);
				letter-spacing: pxToVw(3px);
				line-height: pxToVw(28px);
				@include exo-2-regular;

			}

			&__name {
				margin-left: pxToVw(26px);
				text-transform: uppercase;
				color: #FFFFFF;
				font-size: pxToVw(20px);
				line-height: pxToVw(54px);
				@include exo-2-bold;

				cursor: pointer;

				&--active {
					color: #FAC402;
				}
			}
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.OutletLocationList {
		width: 100%;
		padding: pxToVwMobile(158px) pxToVwMobile(30px) 0;
		&__title {
			font-size: pxToVwMobile(12px);
			line-height: pxToVwMobile(28px);
			margin-bottom: pxToVwMobile(18px);
			letter-spacing: pxToVwMobile(3px);
		}
		&__list {
			width: pxToVwMobile(316px);
			height: pxToVwMobile(390px);

			&__item {
				height: pxToVwMobile(54px);
				border-top: pxToVwMobile(1px) solid rgba(255,255,255,0.47);

				&__number {
					font-size: pxToVwMobile(12px);
					letter-spacing: pxToVwMobile(3px);
					line-height: pxToVwMobile(28px);
				}

				&__name {
					margin-left: pxToVwMobile(26px);
					font-size: pxToVwMobile(20px);
					line-height: pxToVwMobile(54px);
				}
			}
		}
	}
}
