@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.Menu {
	height: 0;
	opacity: 0;
	z-index: 3;
	width: 100%;
	display: none;
	overflow: hidden;
	box-sizing: border-box;
	&__contentContainer {
		z-index: 2;
		width: 100%;
		display: flex;
		position: relative;
		top: pxToVwMobile(2px);
		justify-content: center;
		&__headerMenuLinks {
			display: flex;
			align-items: center;
			flex-direction: column;
			a {
				color: #ffffff;
				text-decoration: none;
				text-transform: uppercase;
				font-size: pxToVwMobile(18px);
				letter-spacing: pxToVwMobile(3px);
				@include exo-2-medium();
				&:not(:last-child) {
					margin-bottom: pxToVwMobile(36px);
				}
			}
			&--active {
				color: #FAC402 !important;
				text-shadow: 0 0 pxToVwMobile(8px) #fac402;
			}
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint;
	containerHeight: pxToVwMobile(246px);
}
