@use "sass:math";

$mobileBreakPoint: 425px;
$screenWidthMobile: 375px;
$screenWidthDesktop: 1308px;

@function pxToVw($pxValue) {
	@return math.div($pxValue, $screenWidthDesktop) * 100vw;
}

@function pxToVwMobile($pxValue) {
	@return math.div($pxValue, $screenWidthMobile) * 100vw;
}

:export {
	mobileBreakPoint: $mobileBreakPoint;
	screenWidthMobile: $screenWidthMobile;
	screenWidthDesktop: $screenWidthDesktop;
}
