@mixin exo-2-light {
  font-family: "Exo 2", sans-serif;
  font-weight: 300;
}
@mixin exo-2-regular {
  font-family: "Exo 2", sans-serif;
  font-weight: 400;
}
@mixin exo-2-bold {
  font-family: "Exo 2", sans-serif;
  font-weight: 700;
}
@mixin exo-2-semibold {
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
}
@mixin exo-2-medium {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
}
@mixin exo-2-black {
  font-family: "Exo 2", sans-serif;
  font-weight: 900;
}
