@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700;900&display=swap');
@import 'src/styles/others.module';

html, body {
  margin: 0;
  padding: 0;
	width: 100%;
	background: #000000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	&::-webkit-scrollbar {
		display: none;
	}
}

#root {
	overflow: hidden;
	position: relative;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
	display:none;
}
.gmnoprint div {
	background:none !important;
}
