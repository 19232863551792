@import "src/styles/typographies.module";
@import 'src/styles/others.module';

.Description {
	width: 100%;
	height: pxToVw(850px);
	position: relative;
	box-sizing: border-box;
	padding-top: pxToVw(310px);
	text-align: center;

	&__title {
		max-width: pxToVw(491px);
		margin: auto;
		text-transform: uppercase;
		color: #FFFFFF;
		@include exo-2-bold;

		h1 {
			font-size: pxToVw(39px);
			line-height: pxToVw(54px);
		}

		&__accent {
			color: #FAC402;
		}
	}

	&__scrollIndicator {
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		position: absolute !important;
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Description {
		padding-top: pxToVwMobile(200px);
		height: pxToVwMobile(754px);

		&__title {
			max-width: pxToVwMobile(316px);

			h1 {
				font-size: pxToVwMobile(36px);
				line-height: pxToVwMobile(54px);
				@include exo-2-black;
			}

			&__accent {
				color: #FAC402;
			}
		}

		&__scrollIndicator {
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			position: absolute !important;
		}
	}
}
