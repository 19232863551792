@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.ProgressBar {
	width: pxToVw(130px);
	height: pxToVw(8px);
	background-color: transparent;

	&__bar {
		height: 100%;
		background-color: #00FFC6;
	}
}
