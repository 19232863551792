@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.FlavorsContent {
	box-sizing: border-box;
	max-width: pxToVw(1000px);
	display: flex;
	align-items: baseline;
	justify-content: center;
	flex-wrap: wrap;

	&__variantCard {
		margin: pxToVw(60px) auto auto;

		&:nth-last-child(1) {
			margin-left: pxToVw(50px);
		}

		&:nth-last-child(2) {
			margin-right: pxToVw(50px);
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.FlavorsContent {

		&__variantCard {
			margin-right:  pxToVwMobile(40px);
		}

		&__first {
			display: flex;
			position: relative;
			right: 110vw;
		}

		&__second {
			display: flex;
			position: relative;
			left: 200vw;
			margin-top: pxToVwMobile(40px);
		}
	}
}
