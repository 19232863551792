@import 'src/styles/others.module';

.Home {
	&__header {
		margin-top: pxToVw(54px);
	}
	&__ticker {
		left: 0;
		right: 0;
		z-index: 3;
		margin: auto;
		top: pxToVw(136px);
		position: absolute;
		u {
			cursor: pointer;
		}
	}
}


@media only screen and (max-width: $mobileBreakPoint) {
	.Home {
		&__header {
			margin-top: unset;
		}
		&__ticker {
			top: pxToVwMobile(88px);
		}
	}
}
