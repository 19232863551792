@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.FlavorsVariant{
	margin-top: pxToVw(72px);
	display: flex;
	flex-direction: column;
	flex-basis: pxToVw(270px);
	align-items: center;

	&__logo {
		display: flex;
		height: pxToVw(140px);
		img {
			margin: auto;
			max-height: 100%;
			width: pxToVw(270px);
		}
	}

	&__content {
		width: 80%;
		margin: pxToVw(32px) auto auto;

		&__title {
			height: pxToVw(64px);
			margin: auto;
			color: #FFFFFF;
			font-size: pxToVw(12px);
			letter-spacing: pxToVw(2px);
			line-height: pxToVw(20px);
			text-transform: uppercase;
			@include exo-2-light();
		}

		&__level {
			width: pxToVw(210px);
			height: pxToVw(40px);
			display: flex;
			align-items: baseline;

			&__label {
				width: pxToVw(70px);
				margin-right: pxToVw(10px);
				color: #FFFFFF;
				font-size: pxToVw(12px);
				letter-spacing: pxToVw(2px);
				line-height: pxToVw(21px);
				text-transform: uppercase;
				@include exo-2-light();
			}

		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.FlavorsVariant{
		margin-top: pxToVwMobile(0px);
		flex-basis: pxToVwMobile(210px);

		&__logo {
			height: pxToVwMobile(108px);
			img {
				width: pxToVwMobile(200px);
			}
		}

		&__content {
			display: none;
		}
	}
}
