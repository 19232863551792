@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.DescriptionDetail {
	z-index: 2;
	position: relative;
	max-width: pxToVw(490px);
	margin-top: pxToVw(96px);
	p {
		&:nth-child(1) {
			color: #ffffff;
			font-size: pxToVw(60px);
			max-width: pxToVw(380px);
			line-height: pxToVw(74px);
			text-transform: uppercase;
			@include exo-2-black();
		}
		&:nth-child(2) {
			color: #ffffff;
			font-size: pxToVw(18px);
			max-width: pxToVw(310px);
			margin-top: pxToVw(40px);
			line-height: pxToVw(28px);
			@include exo-2-light();
		}
	}
	&__exploreMenuButton {
		margin-top: pxToVw(40px);
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.DescriptionDetail {
		max-width: unset;
		margin-top: pxToVwMobile(88px);
		margin-left: pxToVwMobile(30px);
		p {
			&:nth-child(1) {
				font-size: pxToVwMobile(36px);
				max-width: pxToVwMobile(220px);
				line-height: pxToVwMobile(46px);
			}
			&:nth-child(2) {
				font-size: pxToVwMobile(16px);
				max-width: pxToVwMobile(224px);
				margin-top: pxToVwMobile(30px);
				line-height: pxToVwMobile(26px);
			}
		}
		&__exploreMenuButton {
			margin-top: pxToVwMobile(30px);
		}
	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint
}
