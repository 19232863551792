@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

$desktopMarkerSizePx: 16px;

.OutletLocationMarker {
	cursor: pointer;
	position: relative;
	border-radius: 100%;
	width: pxToVw($desktopMarkerSizePx);
	height: pxToVw($desktopMarkerSizePx);
	&:hover p {
		opacity: 1;
		transform: scale(1);
		visibility: visible;
	}
	p {
		z-index: 2;
		opacity: 0;
		display: flex;
		color: #ffffff;
		text-align: center;
		position: absolute;
		top: pxToVw(-32px);
		visibility: hidden;
		white-space: nowrap;
		height: pxToVw(26px);
		transform: scale(0.8);
		box-sizing: border-box;
		font-size: pxToVw(12px);
		text-transform: uppercase;
		transition-duration: 300ms;
		border-radius: pxToVw(13px);
		border: pxToVw(1px) solid #F0C543;
		padding: pxToVw(4px) pxToVw(12px);
		backdrop-filter: blur(pxToVw(8px));
		background-color: rgba(0, 0, 0, 0.4);
		@include exo-2-medium();
	}
	&__outlet {
		background: #FFBB00;
	}
	&__dineIn {
		background: #302DB1;
	}
	&__active {
		&__outlet {
			box-shadow: 0 0 pxToVw(18px) pxToVw(8px) rgba(255, 187, 0, 0.5);
			&::before {
				content: '';
				width: pxToVw(24px);
				height: pxToVw(24px);
				background-color: transparent;
				position: absolute;
				border-radius: 100%;
				top: pxToVw(-6px);
				left: pxToVw(-6px);
				border: solid pxToVw(2px) rgba(255, 187, 0, 0.4);
			}

			&::after {
				content: '';
				width: pxToVw(40px);
				height: pxToVw(40px);
				background-color: transparent;
				position: absolute;
				border-radius: 100%;
				top: pxToVw(-14px);
				left: pxToVw(-14px);
				border: solid pxToVw(2px) rgba(255, 187, 0, 0.05);
			}
		}

		&__dineIn {
			box-shadow: 0 0 pxToVw(18px) pxToVw(8px) rgba(48, 45, 177, 0.5);

			&::before {
				content: '';
				width: pxToVw(24px);
				height: pxToVw(24px);
				background-color: transparent;
				position: absolute;
				border-radius: 100%;
				top: pxToVw(-6px);
				left: pxToVw(-6px);
				border: solid pxToVw(2px) rgba(48, 45, 177, 0.4);
			}

			&::after {
				content: '';
				width: pxToVw(40px);
				height: pxToVw(40px);
				background-color: transparent;
				position: absolute;
				border-radius: 100%;
				top: pxToVw(-14px);
				left: pxToVw(-14px);
				border: solid pxToVw(2px) rgba(48, 45, 177, 0.05);
			}
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.OutletLocationMarker {
		width: pxToVwMobile(16px);
		height: pxToVwMobile(16px);
		p {
			display: none !important;
		}
		&__active {
			&__outlet {
				box-shadow: 0 0 pxToVwMobile(18px) pxToVwMobile(8px) rgba(255, 187, 0, 0.5);
				&::before {
					top: pxToVwMobile(-6px);
					left: pxToVwMobile(-6px);
					width: pxToVwMobile(24px);
					height: pxToVwMobile(24px);
					border: solid pxToVwMobile(2px) rgba(255, 187, 0, 0.4);
				}

				&::after {
					top: pxToVwMobile(-14px);
					left: pxToVwMobile(-14px);
					width: pxToVwMobile(40px);
					height: pxToVwMobile(40px);
					border: solid pxToVwMobile(2px) rgba(255, 187, 0, 0.05);
				}
			}

			&__dineIn {
				box-shadow: 0 0 pxToVwMobile(18px) pxToVwMobile(8px) rgba(48, 45, 177, 0.5);

				&::before {
					top: pxToVwMobile(-6px);
					left: pxToVwMobile(-6px);
					width: pxToVwMobile(24px);
					height: pxToVwMobile(24px);
					border: solid pxToVwMobile(2px) rgba(48, 45, 177, 0.4);
				}

				&::after {
					top: pxToVwMobile(-14px);
					left: pxToVwMobile(-14px);
					width: pxToVwMobile(40px);
					height: pxToVwMobile(40px);
					border: solid pxToVwMobile(2px) rgba(48, 45, 177, 0.05);
				}
			}
		}
	}
}

:export {
	desktopMarkerSizePx: $desktopMarkerSizePx;
}
