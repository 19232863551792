@import "src/styles/typographies.module";
@import 'src/styles/others.module';

.Background {
	width: 100%;
	height: 100%;
	display: flex;
	overflow: hidden;
	position: absolute;
	align-items: center;
	justify-content: center;
	background: linear-gradient(0deg, #121141 0%, #142027 100%);
	&::before {
		top: 0;
		content: '';
		width: 100%;
		position: absolute;
		height: pxToVw(308px);
		background: linear-gradient(180deg, #142027 0%, rgba(20,32,39,0) 100%);
	}
	&__bottomFilter {
		width: 100%;
		height: pxToVw(308px);
		position: absolute;
		bottom: pxToVw(-95px);
		background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
	}
	&__orbitContainer {
		z-index: 1;
		display: flex;
		position: absolute;
		align-items: center;
		width: pxToVw(560px);
		height: pxToVw(184px);
		justify-content: center;
		margin-top: pxToVw(-4px);
		&__outerOrbit {
			width: 100%;
			position: absolute;
			path {
				stroke: #ffffff;
				stroke-opacity: 0.22;
				stroke-width: pxToVw(1px);
			}
		}
		&__innerOrbit {
			position: absolute;
			width: pxToVw(400px);
			height: pxToVw(130px);
			ellipse {
				stroke: #ffffff;
				stroke-opacity: 1;
				stroke-width: pxToVw(1px);
			}
			&__orbitShadow {
				stroke-opacity: 0.2 !important;
			}
		}
	}
	&__lineHero {
		position: absolute;
		opacity: 0.1
	}
	&__sideFilter {
		width: 100%;
		height: 100%;
		position: absolute;
		&::before {
			content: '';
			width: pxToVw(594px);
			height: 100%;
			position: absolute;
			left: 0;
			opacity: 0.8;
			background: linear-gradient(270deg, rgba(0,0,0,0) 0%, #000000 60%);
		}
		&::after {
			content: '';
			width: pxToVw(594px);
			height: 100%;
			position: absolute;
			right: 0;
			opacity: 0.8;
			background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #000000 60%);
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Background {
		&::before {
			height: pxToVwMobile(308px);
			top: pxToVwMobile(28px);
		}
		&__bottomFilter {
			height: pxToVwMobile(277px);
			bottom: pxToVwMobile(0px);
		}
		&__orbitContainer {
			width: pxToVwMobile(336px);
			height: pxToVwMobile(110px);
			margin-top: pxToVwMobile(-36px);
			&__outerOrbit {
				path {
					stroke-opacity: 0.3;
					stroke-width: pxToVwMobile(1px);
				}
			}
			&__innerOrbit {
				width: pxToVwMobile(240px);
				height: pxToVwMobile(78px);
				ellipse {
					stroke-width: pxToVwMobile(1px);
				}
				&__orbitShadow {
					stroke-opacity: 0.3 !important;
				}
			}
		}
		&__sideFilter {
			&::before {
				height: 100%;
				width: pxToVwMobile(175px);
				background: linear-gradient(270deg, rgba(0,0,0,0) 0%, #000000 100%);
			}
			&::after {
				height: 100%;
				width: pxToVwMobile(175px);
				background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #000000 100%);
			}
		}
	}
}
