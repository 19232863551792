@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.AvailabilityDetails {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: pxToVw(92px) 0 pxToVw(150px) 0;

	&__title {
		margin-bottom: pxToVw(80px);
		color: #FFFFFF;
		font-size: pxToVw(40px);
		line-height: pxToVw(54px);
		text-align: center;
		@include exo-2-bold();
	}

	&__content {
		display: flex;
		justify-content: space-between;
		width: pxToVw(934px);
		&__image {
			display: flex;
			align-items: center;
			&__GrabFood {
				width: pxToVw(178px);
			}

			&__GoFood {
				width: pxToVw(148px);
			}

			&__ShopeeFood {
				width: pxToVw(144px);
			}

			&__HangryApp {
				width: pxToVw(170px);
			}

		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.AvailabilityDetails {

		margin: pxToVwMobile(60px) 0 pxToVwMobile(128px) 0;

		&__title {
			margin-bottom: pxToVwMobile(20px);
			color: #FFFFFF;
			font-size: pxToVwMobile(24px);
			line-height: pxToVwMobile(34px);
			text-align: center;
			text-transform: uppercase;
			@include exo-2-black();
		}

		&__content {
			z-index: 2;
			display: flex;
			flex-wrap: wrap;
			position: relative;
			align-items: center;
			width: pxToVwMobile(318px);
			justify-content: space-between;

			&__image {
				display: flex;
				align-items: flex-end;
				height: pxToVwMobile(84px);
				flex-basis: pxToVwMobile(144px);

				&__GrabFood {
					width: pxToVwMobile(144px);
				}

				&__GoFood {
					width: pxToVwMobile(120px);
				}

				&__ShopeeFood {
					width: pxToVwMobile(118px);
				}

				&__HangryApp {
					width: pxToVwMobile(138px);
				}

			}
		}
	}
}
