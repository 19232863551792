@import 'src/styles/others.module';

.OutletLocationContent {
	position: relative;
	min-height: pxToVw(1294px);
}

@media only screen and (max-width: $mobileBreakPoint) {
	.OutletLocationContent {
		min-height: pxToVwMobile(974px);
	}
}