@import "src/styles/typographies.module";
@import 'src/styles/others.module';

.Background {
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	&__backgroundImage {
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		&__imageContainer {
			display: flex;
			justify-content: center;
		}
		img {
			width: 100%;
			opacity: 0.6;
			top: pxToVw(0px);
			position: absolute;
			filter: saturate(0.96) brightness(0.65) contrast(2.4);
		}
		img:not(:first-child) {
			opacity: 0;
			display: none;
		}
		&__storeTexture {
			opacity: 0.5;
			position: absolute;
			left: pxToVw(58px);
			width: pxToVw(1196px);
			mask-image: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,1) 58%, rgba(0,0,0,0) 70%);
		}
	}
	&__gridLine {
		height: 97%;
		opacity: 0.48;
		position: absolute;
		left: pxToVw(-14px);
	}
	&__filter {
		width: 100%;
		height: 100%;
		position: absolute;
		background-position: pxToVw(2px);
		background-size: pxToVw(14px) pxToVw(14px);
		background-image: radial-gradient(rgba(255, 255, 255, 0.05) pxToVw(1px), transparent 0);

		&:after {
			bottom: 0;
			content: '';
			width: 100%;
			position: absolute;
			height: pxToVw(250px);
			background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 85%);
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Background {
		&__backgroundImage {
			img {
				left: auto;
				right: auto;
				margin: auto;
				width: unset;
				height: 100%;
			}
			&__storeTexture {
				left: pxToVwMobile(-262px);
			}
		}
		&__gridLine {
			left: pxToVwMobile(-14px);
		}
		&__filter {
			background-position: pxToVwMobile(2px);
			background-size: pxToVwMobile(14px) pxToVwMobile(14px);
			background-image: radial-gradient(rgba(255, 255, 255, 0.05) pxToVwMobile(1px), transparent 0);
			&:after {
				height: pxToVwMobile(250px);
			}
		}
	}
}
