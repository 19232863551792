@import 'src/styles/others.module';

.OutletLocation {
	&__header {
		margin-top: pxToVw(54px);
	}
}


@media only screen and (max-width: $mobileBreakPoint) {
	.OutletLocation {
		&__header {
			margin-top: unset;
		}
	}
}
