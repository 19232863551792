@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.DescriptionDetail {
	z-index: 2;
	position: relative;
	display: flex;
	margin-top: pxToVw(100px);
	width: 100%;
	justify-content: space-between;

	&__text {
		display: flex;
		flex-direction: column;
		margin-top: pxToVw(50px);

		p {
			&:nth-child(1) {
				color: #FAC402;
				font-size: pxToVw(40px);
				line-height: pxToVw(54px);
				text-transform: uppercase;
				@include exo-2-bold();
			}
			&:nth-child(2) {
				color: #ffffff;
				font-size: pxToVw(18px);
				max-width: pxToVw(284px);
				margin-top: pxToVw(40px);
				line-height: pxToVw(28px);
				@include exo-2-light();
			}
		}
	}

	&__image {
		max-width: pxToVw(600px);
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.DescriptionDetail {
		flex-direction: column;
		margin-top: pxToVwMobile(86px);
		margin-left: pxToVwMobile(30px);

		&__text {
			margin-top: 0;

			p {
				&:nth-child(1) {
					font-size: pxToVwMobile(36px);
					line-height: pxToVwMobile(46px);
					@include exo-2-black();
				}
				&:nth-child(2) {
					font-size: pxToVwMobile(16px);
					max-width: pxToVwMobile(222px);
					margin-top: pxToVwMobile(30px);
					line-height: pxToVwMobile(26px);
				}
			}
		}

		&__image {
			max-width: pxToVwMobile(356px);
			position: relative;
			right: pxToVwMobile(-22px);
		}
	}
}
