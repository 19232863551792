@import 'src/styles/others.module';

.PartnershipForm {
	margin-top: pxToVw(116px);
	display: flex;
	flex-direction: column;
	align-items: center;

	&__field {
		margin-bottom: pxToVw(40px);
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.PartnershipForm {
		margin-top: pxToVwMobile(116px);

		&__field {
			margin-bottom: pxToVwMobile(40px);
		}

	}
}

:export {
	mobileBreakPoint: $mobileBreakPoint
}
