@import 'src/styles/others.module';

.Hero {
	width: 100%;
	height: pxToVw(850px);
	position: relative;
	background-color: #000000;
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Hero {
		height: pxToVwMobile(754px);
	}
}
