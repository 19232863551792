@import 'src/styles/others.module';

.About {
	position: relative;
	height: pxToVw(1400px);
}

@media only screen and (max-width: $mobileBreakPoint) {
	.About {
		height: pxToVwMobile(1297px);
	}
}
