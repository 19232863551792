@import 'src/styles/typographies.module';
@import 'src/styles/others.module';

.Footer {
	display: flex;
	align-items: center;
	height: pxToVw(384px);
	padding: 0 pxToVw(160px);
	background-color: #1B1A5A;
	justify-content: space-between;
	background-size: pxToVw(108px) pxToVw(108px);
	background-image:
		linear-gradient(to right, rgba(233, 18, 116, 0.3) pxToVw(1px), transparent pxToVw(1px)),
		linear-gradient(to bottom, rgba(233, 18, 116, 0.3) pxToVw(1px), transparent pxToVw(1px));
	background-position: pxToVw(54px) 0, 0 pxToVw(-1px);

	color: #FFFFFF;
	@include exo-2-light;

	.yellow {
		color: #FAC402;
		text-decoration: none;
	}

	.Footer_heading {
		margin: 0;
		opacity: 0.6;
		display: inline-block;
		font-size: pxToVw(12px);
		line-height: pxToVw(28px);
		letter-spacing: pxToVw(4px);
		@include exo-2-light;
	}

	.Footer_content {
		margin-top: pxToVw(24px);
		display: flex;
		flex-direction: column;

		font-size: pxToVw(18px);
		letter-spacing: 0;
		line-height: pxToVw(28px);
		@include exo-2-light;
	}

	.Footer_explore {
		.Footer_explore_navlink {
			color: #FFFFFF;
			text-decoration: none;
			text-transform: capitalize;
		}

		.active {
			color: #FAC402;
		}
	}

	.Footer_social_icons_container {
		display: flex;
		align-items: flex-end;
		margin-top: pxToVw(20px);

		.Footer_social_icons {
			margin-right: pxToVw(14px);
			color: #FFFFFF;
			font-size: pxToVw(20px);
		}
	}

	.Footer_web {
		.Footer_content {
			margin-top: pxToVw(60px);
			a {
				text-decoration: none;
			}

			.Footer_copyright {
				opacity: 0.4;
				margin-top: pxToVw(20px);

				font-size: pxToVw(14px);
				letter-spacing: 0;
				line-height: pxToVw(18px);
				@include exo-2-light;
			}
		}
	}

	.Footer_get_app {
		.Footer_content_get_app {
			img {
				width: pxToVw(140px);

				&:first-child {
					margin-bottom: pxToVw(8px);
				}
			}
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Footer {
		z-index: 2;
		position: relative;
		flex-direction: column;
		box-sizing: border-box;
		align-items: flex-start;
		height: pxToVwMobile(862px);
		background-size: pxToVwMobile(105px) pxToVwMobile(105px);
		background-position: pxToVwMobile(28px) 0, 0 pxToVwMobile(-1px);
		padding: pxToVwMobile(50px) pxToVwMobile(28px) pxToVwMobile(48px) pxToVwMobile(28px);
		background-image:
			linear-gradient(to right, rgba(233, 18, 116, 0.3) pxToVwMobile(1px), transparent pxToVwMobile(1px)),
			linear-gradient(to bottom, rgba(233, 18, 116, 0.3) pxToVwMobile(1px), transparent pxToVwMobile(1px));
		.Footer_section {
			text-align: left;
			.Footer_heading {
				font-size: pxToVwMobile(12px);
				line-height: pxToVwMobile(28px);
				padding-bottom: pxToVwMobile(4px);
				border-bottom: pxToVwMobile(1px) solid rgba(255,255,255,0.5);
			}
			.Footer_content:not(:first-child) {
				font-size: pxToVwMobile(18px);
				margin-top: pxToVwMobile(30px);
				line-height: pxToVwMobile(28px);
			}
		}

		.Footer_social_icons_container {
			margin-top: pxToVwMobile(20px);
			.Footer_social_icons {
				font-size: pxToVwMobile(24px);
				margin-right: pxToVwMobile(14px);
			}
		}

		.Footer_web {
			.Footer_content {
				margin: 0;
				p, a {
					font-size: pxToVwMobile(18px);
					line-height: pxToVwMobile(28px);
				}
				.Footer_copyright {
					margin-top: pxToVwMobile(16px);
					p {
						font-size: pxToVwMobile(14px);
						line-height: pxToVwMobile(18px);
					}
				}
			}
		}

		.Footer_get_app {
			.Footer_content_get_app {
				a {
					line-height: 0;
					&:first-child {
						margin-bottom: pxToVwMobile(16px);
					}
				}
				img {
					margin: 0 !important;
					width: pxToVwMobile(140px);
				}
			}
		}
	}
}
