@import "src/styles/typographies.module";
@import 'src/styles/others.module';

.Background {
	width: 100%;
	height: 100%;
	position: absolute;
	background: linear-gradient(180deg, transparent	, #142027 40%, #142027 80%, #121141 100%);

	&__backgroundImage {
		width: 100%;
		height: 100%;
		position: absolute;
		opacity: 0.5;

		&__meteor2 {
			height: pxToVw(94px);
			position: absolute;
			top: pxToVw(-30px);
			right: 0;
			filter: contrast(1.2) brightness(2);
		}

		&__meteor1 {
			height: pxToVw(172px);
			position: absolute;
			top: pxToVw(210px);
			right: pxToVw(108px);
			filter: contrast(1.5) brightness(1.5);
		}

		&__meteor4 {
			z-index: 3;
			width: pxToVw(182px);
			position: absolute;
			top: pxToVw(1091px);
			left: pxToVw(160px);
			filter: contrast(2) brightness(1.5);
		}

		&__meteor3 {
			width: pxToVw(96px);
			position: absolute;
			top: pxToVw(1270px);
			right: pxToVw(242px);
			filter: contrast(2) brightness(1.5);
		}

		&__moon {
			height: 100%;
			width: pxToVw(458px);
			position: absolute;

			&::before {
				z-index: 2;
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				background: linear-gradient(180deg, black, #142027 40%, #142027 80%, #121141 100%);
				-webkit-mask: radial-gradient(circle at 0 pxToVw(650px), rgba(0,0,0,0) pxToVw(315px), #142027 pxToVw(328px));
			}

			&::after {
				right: 0;
				top: pxToVw(200px);
				z-index: 1;
				content: '';
				width: 150%;
				position: absolute;
				height: pxToVw(256px);
				transform: rotateZ(15deg) translateY(pxToVw(104px));
				background: linear-gradient(
						180deg,
						#0F1218 15%,
						rgba(15, 18, 24, 0.4) 35%,
						rgba(15, 18, 24, 0.2) 50%,
						rgba(0, 0, 0, 0) 100%
				);
			}

			&__img {
				height: pxToVw(868px);
				width: pxToVw(458px);
				position: absolute;
				margin-top: pxToVw(210px);
				opacity: 0.55;
				filter: saturate(300%) brightness(0.62) contrast(2.5) hue-rotate(-15deg) sepia(0.2);
			}
		}
	}

	&__grid {
		height: 100%;

		&__center {
			height: pxToVw(1px);
			width: 100%;
			position: absolute;
			top: pxToVw(450px);
			background: linear-gradient(to right, rgba(255,255,255,0.05) pxToVw(540px), rgba(255,255,255,0.2) pxToVw(540px), rgba(255,255,255,0.2) pxToVw(590px), rgba(255,255,255,0.05) pxToVw(590px));

			&:before {
				content: '';
				width: pxToVw(1px);
				height: pxToVw(312px);
				position: absolute;
				top: pxToVw(-182px);
				left: pxToVw(565px);
				background: linear-gradient(to bottom, rgba(255,255,255,0.05) pxToVw(157px), rgba(255,255,255,0.2) pxToVw(157px), rgba(255,255,255,0.2) pxToVw(207px), rgba(255,255,255,0.05) pxToVw(207px), transparent pxToVw(300px));
			}
		}

		&__vertical {
			width: pxToVw(1px);
			height: pxToVw(1232px);
			position: absolute;
			top: pxToVw(-200px);
			left: pxToVw(156px);
			background: linear-gradient(to bottom, transparent,  rgba(255,255,255,0.05) pxToVw(150px), rgba(255,255,255,0.05) pxToVw(900px), transparent);

			&:before {
				content: '';
				width: pxToVw(1px);
				height: pxToVw(1000px);
				position: absolute;
				top: 0;
				left: pxToVw(1000px);
				background: linear-gradient(to bottom, transparent pxToVw(190px), rgba(255,255,255,0.2) pxToVw(400px), rgba(117, 78, 78, 0.05) pxToVw(900px), transparent pxToVw(1000px));
			}
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Background {
		&__backgroundImage {

			&__meteor2 {
				height: pxToVwMobile(70px);
				z-index: 3;
			}

			&__meteor1 {
				height: pxToVwMobile(140px);
				top: pxToVwMobile(280px);
				right: pxToVwMobile(20px);
				z-index: 3;
			}

			&__meteor4 {
				width: pxToVwMobile(84px);
				top: pxToVwMobile(1145px);
				left: pxToVwMobile(27px);
				z-index: 3;
			}

			&__meteor3 {
				width: pxToVwMobile(86px);
				top: pxToVwMobile(1320px);
				right: pxToVwMobile(-30px);
				z-index: 3;
			}

			&__moon {
				width: pxToVwMobile(458px);
				left: pxToVwMobile(-200px);

				&::before {
					-webkit-mask: radial-gradient(circle at 0 pxToVwMobile(740px), rgba(0,0,0,0) pxToVwMobile(324px), #142027 pxToVwMobile(334px));
				}

				&::after {
					height: pxToVwMobile(256px);
					top: pxToVwMobile(300px);
					transform: rotateZ(15deg) translateY(pxToVwMobile(104px));
				}

				&__img {
					height: pxToVwMobile(868px);
					width: pxToVwMobile(458px);
					margin-top: pxToVwMobile(300px);
				}
			}
		}

		&__grid {
			height: 100%;

			&__center {
				height: pxToVwMobile(1px);
				width: 100%;
				position: absolute;
				top: pxToVwMobile(390px);
				background: linear-gradient(to right, rgba(255,255,255,0.05) pxToVwMobile(223px), rgba(255,255,255,0.2) pxToVwMobile(223px), rgba(255,255,255,0.2) pxToVwMobile(273px), rgba(255,255,255,0.05) pxToVwMobile(273px));

				&:before {
					content: '';
					width: pxToVwMobile(1px);
					height: pxToVwMobile(286px);
					position: absolute;
					top: pxToVwMobile(-182px);
					left: pxToVwMobile(247.5px);
					background: linear-gradient(to bottom, rgba(255,255,255,0.05) pxToVwMobile(157px), rgba(255,255,255,0.2) pxToVwMobile(157px), rgba(255,255,255,0.28) pxToVwMobile(207px), rgba(255,255,255,0.05) pxToVwMobile(207px), transparent pxToVwMobile(300px));
				}
			}

			&__vertical {
				width: pxToVwMobile(1px);
				height: pxToVwMobile(939px);
				top: pxToVwMobile(-230px);
				left: pxToVwMobile(346px);
				background: linear-gradient(to bottom, transparent,  rgba(255,255,255,0.057) pxToVwMobile(150px), rgba(255,255,255,0.057) pxToVwMobile(750px), transparent pxToVwMobile(920px));

				&:before {
					visibility: hidden;
					content: '';
					width: pxToVwMobile(1px);
					height: pxToVwMobile(1000px);
					position: absolute;
					top: 0;
					left: pxToVwMobile(1000px);
					background: linear-gradient(to bottom, transparent pxToVwMobile(190px), rgba(255,255,255,0.6) pxToVwMobile(400px), rgba(255,255,255,0.057) pxToVwMobile(500px), transparent pxToVwMobile(1000px));
				}
			}
		}
	}
}
