@import 'src/styles/others.module';

.Background {
	height: 100%;
	width: 100%;
	position: absolute;
	background: linear-gradient(180deg, transparent, #142027 pxToVw(80px), #142027 pxToVw(1210px), #121141 pxToVw(1800px));

	&__lines {

		&__mainLines {
			width: 100%;
			height: pxToVw(1px);
			position: absolute;
			top: pxToVw(476px);
			background: linear-gradient(
				to right,
				rgba(255,255,255,0.1) pxToVw(470px),
				rgba(255, 255, 255, 0.2) pxToVw(470px),
				rgba(255, 255, 255, 0.2) pxToVw(520px),
				rgba(255,255,255,0.1) pxToVw(520px)
			);

			&::after {
				content: '';
				width: pxToVw(1px);
				height: pxToVw(538px);
				position: absolute;
				top: pxToVw(-181.5px);
				left: pxToVw(495px);
				background: linear-gradient(
					to bottom,
					rgba(255,255,255,0.1) pxToVw(156.5px),
					rgba(255, 255, 255, 0.2) pxToVw(156.5px),
					rgba(255, 255, 255, 0.2) pxToVw(206.5px),
					rgba(255,255,255,0.1) pxToVw(206.5px),
					transparent
				);
			}
		}

		&__verticalLine {
			width: pxToVw(1px);
			height: pxToVw(800px);
			position: absolute;
			right: pxToVw(158px);
			background: linear-gradient(
				to bottom,
				transparent,
				rgba(255,255,255,0.38) pxToVw(150px),
				rgba(255, 255, 255, 0.38) pxToVw(200px),
				rgba(255, 255, 255, 0.1) pxToVw(300px),
				transparent
				);
			z-index: 2;
		}

		&__secondaryLines {
			width: 100%;
			height: pxToVw(1px);
			position: absolute;
			bottom: pxToVw(1030px);
			background: linear-gradient(
				to left,
				rgba(255,255,255,0.1) pxToVw(460px),
				rgba(255, 255, 255, 0.3) pxToVw(460px),
				rgba(255, 255, 255, 0.3) pxToVw(510px),
				rgba(255,255,255,0.1) pxToVw(510px)
			);

			&::after {
				content: '';
				width: pxToVw(1px);
				height: pxToVw(538px);
				position: absolute;
				top: pxToVw(-181.5px);
				right: pxToVw(485px);
				background: linear-gradient(
					to bottom,
					rgba(255,255,255,0.1) pxToVw(156.5px),
					rgba(255, 255, 255, 0.3) pxToVw(156.5px),
					rgba(255, 255, 255, 0.3) pxToVw(206.5px),
					rgba(255,255,255,0.1) pxToVw(206.5px),
					transparent
				);
			}
		}
	}

	&__menuBgMoon {
		width: 100%;
		height: pxToVw(550px);
		position: absolute;
		bottom: 0;

		&::before {
			content: '';
			width: 100%;
			height: pxToVw(600px);
			background: linear-gradient(180deg, #142027 30%, rgba(18, 17, 65, 0.5) 75%, transparent);
			position: absolute;
			top: pxToVw(-300px);
			z-index: 1;
		}

		&::after {
			content: '';
			width: 100%;
			height: pxToVw(150px);
			background: linear-gradient(0deg, #121141 45%, transparent 100%);
			position: absolute;
			bottom: 0;
			z-index: 2;
		}

		&__image {
			height: pxToVw(780px);
			opacity: 0.05;
			position: absolute;
			bottom: pxToVw(-250px);
		}

		&__lines {
			width: pxToVw(952px);
			height: pxToVw(1px);
			position: absolute;
			top: pxToVw(324px);
			left: pxToVw(80px);
			background: linear-gradient(
				to right,
				transparent,
				rgba(255, 255, 255, 0.1) 10%,
				rgba(255, 255, 255, 0.2) 20%,
				rgba(255, 255, 255, 0.2) 80%,
				rgba(255, 255, 255, 0.1) 90%,
				transparent);
			z-index: 3;

			&::before {
				content: '';
				width: pxToVw(1px);
				height: pxToVw(400px);
				position: absolute;
				top: pxToVw(-135px);
				left: pxToVw(190px);
				background: linear-gradient(
					to bottom,
					transparent,
					rgba(255, 255, 255, 0.1) 20%,
					rgba(255, 255, 255, 0.2) 80%
					);
			}
		}
	}

	&__meteorImg {

		img {
			z-index: 2;
		}

		&__meteor7 {
			position: absolute;
			right: 0;
			bottom: pxToVw(550px);
			width: pxToVw(94px);
			filter: brightness(0.9) contrast(1.1);
		}

		&__meteor8 {
			width: pxToVw(86px);
			position: absolute;
			bottom: pxToVw(300px);
			right: pxToVw(146px);
		}
	}
}

@media only screen and (max-width: $mobileBreakPoint) {
	.Background {
		height: 100%;
		width: 100%;
		position: absolute;
		background: linear-gradient(180deg, #142027 pxToVwMobile(80px), #142027 pxToVwMobile(1416px), #121141 pxToVwMobile(1900px));

		&__lines {
			display: none;

			&__mainLines {
				width: 100%;
				height: pxToVwMobile(1px);
				position: absolute;
				top: pxToVwMobile(510px);
				background: linear-gradient(
					to right,
					rgba(255,255,255,0.1) pxToVwMobile(470px),
					rgba(255, 255, 255, 0.2) pxToVwMobile(470px),
					rgba(255, 255, 255, 0.2) pxToVwMobile(520px),
					rgba(255,255,255,0.1) pxToVwMobile(520px)
				);

				&::after {
					content: '';
					width: pxToVwMobile(1px);
					height: pxToVwMobile(538px);
					position: absolute;
					top: pxToVwMobile(-181.5px);
					left: pxToVwMobile(495px);
					background: linear-gradient(
						to bottom,
						rgba(255,255,255,0.1) pxToVwMobile(156.5px),
						rgba(255, 255, 255, 0.2) pxToVwMobile(156.5px),
						rgba(255, 255, 255, 0.2) pxToVwMobile(206.5px),
						rgba(255,255,255,0.1) pxToVwMobile(206.5px),
						transparent
					);
				}
			}

			&__verticalLine {
				width: pxToVwMobile(1px);
				height: pxToVwMobile(800px);
				position: absolute;
				right: pxToVwMobile(158px);
				background: linear-gradient(
					to bottom,
					transparent,
					rgba(255,255,255,0.38) pxToVwMobile(150px),
					rgba(255, 255, 255, 0.38) pxToVwMobile(200px),
					rgba(255, 255, 255, 0.1) pxToVwMobile(300px),
					transparent
					);
				z-index: 2;
			}

			&__secondaryLines {
				width: 100%;
				height: pxToVwMobile(1px);
				position: absolute;
				bottom: pxToVwMobile(1030px);
				background: linear-gradient(
					to left,
					rgba(255,255,255,0.1) pxToVwMobile(460px),
					rgba(255, 255, 255, 0.2) pxToVwMobile(460px),
					rgba(255, 255, 255, 0.2) pxToVwMobile(510px),
					rgba(255,255,255,0.1) pxToVwMobile(510px)
				);

				&::after {
					content: '';
					width: pxToVwMobile(1px);
					height: pxToVwMobile(538px);
					position: absolute;
					top: pxToVwMobile(-181.5px);
					right: pxToVwMobile(485px);
					background: linear-gradient(
						to bottom,
						rgba(255,255,255,0.1) pxToVwMobile(156.5px),
						rgba(255, 255, 255, 0.2) pxToVwMobile(156.5px),
						rgba(255, 255, 255, 0.2) pxToVwMobile(206.5px),
						rgba(255,255,255,0.1) pxToVwMobile(206.5px),
						transparent
					);
				}
			}
		}

		&__menuBgMoon {
			width: 100%;
			height: pxToVwMobile(420px);
			position: absolute;
			bottom: 0;

			&::before {
				display: none;
			}

			&::after {
				content: '';
				width: 100%;
				height: pxToVwMobile(284px);
				background: linear-gradient(0deg, #121141 0%, transparent 100%);
				position: absolute;
				bottom: 0;
				z-index: 2;
			}

			&__image {
				height: pxToVwMobile(720px);
				opacity: 0.05;
				position: absolute;
				bottom: pxToVwMobile(-250px);
				left: -128%;
				top: pxToVwMobile(-10px);
			}

			&__lines {
				width: 100%;
				height: pxToVwMobile(1px);
				position: absolute;
				top: pxToVwMobile(420px);
				left: pxToVwMobile(0px);
				background: linear-gradient(
					to right,
					transparent,
					rgba(255, 255, 255, 0.1) 10%,
					rgba(255, 255, 255, 0.2) 20%,
					rgba(255, 255, 255, 0.2) 80%,
					rgba(255, 255, 255, 0.1) 90%,
					transparent);
				z-index: 3;

				&::before {
					content: '';
					width: pxToVwMobile(1px);
					height: pxToVwMobile(200px);
					position: absolute;
					top: pxToVwMobile(-55px);
					left: pxToVwMobile(28px);
					background: linear-gradient(
						to bottom,
						transparent,
						rgba(255, 255, 255, 0.1) 20%,
						rgba(255, 255, 255, 0.2) 80%
						);
				}
			}
		}

		&__meteorImg {

			&__meteor7 {
				position: absolute;
				right: 0;
				bottom: pxToVwMobile(340px);
				width: pxToVwMobile(78px);
				opacity: 0.36;
			}

			&__meteor8 {
				width: pxToVwMobile(60px);
				position: absolute;
				bottom: pxToVwMobile(234px);
				right: pxToVwMobile(72px);
				opacity: 0.8;
			}
		}
	}
}
